import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls';

import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { Container, FooterContainer } from './styles';
import { useHistory } from 'react-router-dom';
import { ModalSelectGroupUsers } from './Components/modalSelectedGroupUsers';
import { ModalSelectedCompanies } from './Components/modalSelectedCompanies';
import { ADD_CREDIT_MANAGEMENT_GROUP, GET_LIST_ALL_CREDIT_TYPES_ACTIVED, GET_LIST_ALL_CREDIT_TYPES_ACTIVED_CHANGED_POLICY } from 'src/services/manageCredtis';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { CreateAndEditCreditTypesManagemnt } from '../Create';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
interface ErrorRequestApi {
  response: {
    data: {
      message: string;
    };
  };
}

interface ListCreditType {
  description: string;
  id: number;
}

export function ManagementCreditTypesManagemnt() {
  const history = useHistory();
  const intl = useIntl();
  const [tabActived, seTabActived] = useState(0);
  const [isModalPersonsActived, setIsModalPersonsActived] = useState(false);
  const [isModalCompaniesActived, setIsModalCompaniesActived] = useState(false);

  const [userId, setUserId] = useState(0);
  const [userData, setDataUser] = useState('');

  const [companiesId, setCompaniesId] = useState(0);
  const [companiesData, setCompaniesData] = useState('');

  const [addCredit, setAddCredit] = useState(true);
  const [ajustCredit, setAjustCredit] = useState(false);

  const [listCreditsTypes, setListCreditsTypes] = useState<ListCreditType[]>([]);
  const [typeCreditSelected, setTtypeCreditSelected] = useState(0);

  const refValueInserted = useRef<HTMLInputElement>(null);
  const refValueInsertedAjusted = useRef<HTMLInputElement>(null);

  const [isRead, setIsRead] = useState(false);
  const { redirectView, actionsIsDesatived } = usePermisssion();

  const [isRequestApi, setRequestApi] = useState(false)

  useEffect(() => {
    redirectView(17);
    const value = actionsIsDesatived(17);
    setIsRead(value);
  }, []);

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  function toggleModal() {
    setIsModalPersonsActived(!isModalPersonsActived);
  }

  function handleSelectedUser(item: { id: number; description: string }) {
    if (item.id === userId) {
      setUserId(0);
      setDataUser('');

      return;
    }
    setUserId(item.id);
    setDataUser(item.description);
  }

  function handleSelectedGroup(item: { id: number; description: string }) {
    if (item.id === userId) {
      setCompaniesId(0);
      setCompaniesData('');

      return;
    }
    setCompaniesId(item.id);
    setCompaniesData(item.description);
  }

  function hanldeAddListUsers() {
    toggleModal();
  }

  function hanldeAddListCompanies() {
    setIsModalCompaniesActived(false);
  }

  async function getAllCreditTypes() {
    try {
      const { data } = await GET_LIST_ALL_CREDIT_TYPES_ACTIVED_CHANGED_POLICY({
        pageNumber: 1,
        pageSize: 1000,
        sortOrder: 'asc',
        status: '1',
        sortField: 'Description'
      });

      const values = data.data.data;

      setListCreditsTypes(values);
    } catch { }
  }

  async function handleSave() {
    let value = '0';

    if (refValueInserted.current && refValueInsertedAjusted.current) {
      if (addCredit) {
        value = refValueInserted.current?.value;
      } else {
        value = refValueInsertedAjusted.current?.value;
      }
    }

    if (+value <= 0) {
      return execToast('error', INTL('MANAGER_CREDIT_ERROR_VALUE'), 3000);
    }

    const data: any = {
      'creditTypeId': typeCreditSelected,
      'currentBalance': value,
      'groupIds': [],
      'managementType': addCredit ? 'Add' : 'Adjust'
    };

    if (companiesId !== 0) {
      data.groupIds.push(companiesId);
    }

    if (userId !== 0) {
      data.groupIds.push(userId);
    }

    try {
      setRequestApi(true)
      if (!userId && !companiesId) {
        throw new Error('Is select one user');
      }

      if (!typeCreditSelected) {
        throw new Error('Is selecte type credit');
      }

      await ADD_CREDIT_MANAGEMENT_GROUP(data);

      execToast('success', INTL('MANAGEMENT_TYPES_CREDITS_EDIT_SUCCESS'), 3000);
      history.push('/creditmanagement');
      setRequestApi(false)
    } catch (erro) {
      if (erro.message === 'Is selecte type credit') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_TYPECREDIT'), 3000);
        return;
      }

      if (erro.message === 'Is select one user') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_NO_PERSON'), 3000);
        return;
      }

      if (erro.response.data.message == '410') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_IS_EXISTING'), 3000);
        return;
      }

      if (erro.response.data.message == '404') {
        execToast('error', INTL('MANAGEMENT_TYPES_CREDITS_CREATE_PERSON_OR_TYPECREDIT_NOT_EXISTING'), 3000);
        return;
      }
      setRequestApi(false)
    }
  }

  useEffect(() => {
    getAllCreditTypes();
  }, []);

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={undefined}
        title={
          tabActived === 0
            ? INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_ADJUSTMENT')
            : INTL('ITEM_MANAGER_CREDIT_BUTTON')
        }
      />

      <CardBody fit={null} fluid={null} className={null}>
        <ul className='nav nav-dark nav-bold nav-tabs nav-tabs-line' data-remember-tab='tab_id' role='tablist'>
          <li className='nav-item'>
            <a
              className={`nav-link ${tabActived === 0 ? 'active' : ''}`}
              data-toggle='tab'
              onClick={() => {
                seTabActived(0);
              }}>
              {INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_ADJUSTMENT')}
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link ${tabActived === 1 ? 'active' : ''}`}
              data-toggle='tab'
              onClick={() => {
                seTabActived(1);
              }}>
              {INTL('ITEM_MANAGER_CREDIT_BUTTON')}
            </a>
          </li>
        </ul>

        {tabActived === 0 && (
          <Container>
            <div>
              <div className='path'>
                <label htmlFor=''>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_GROUPS_OF_PEOPLE')}</label>
                <span style={{ width: '310px' }} onClick={toggleModal} className='form-control'>
                  {userData === '' ? INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_GROUPS_OF_PEOPLE') : userData}
                </span>
              </div>

              <div className='path'>
                <label htmlFor=''>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_TYPE_CREDIT')}</label>
                <select
                  value={typeCreditSelected}
                  className='custom-select'
                  onChange={(e) => {
                    setTtypeCreditSelected(+e.target.value);
                  }}>
                  <option value='0'>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_TYPE_CREDIT')}</option>
                  {listCreditsTypes.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.description}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mt'>
                <span>
                  <ToggleSwitch
                    checked={addCredit}
                    onChange={(e) => {
                      setAddCredit(e.target.checked);

                      if (e.target.checked) {
                        setAjustCredit(false);
                        setAddCredit(e.target.checked);
                      } else {
                        setAjustCredit(true);
                        setAddCredit(false);
                      }
                    }}
                  />{' '}
                  {INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_ADD_CASH')}
                </span>
                <input ref={refValueInserted} disabled={ajustCredit} type='number' min='0' className='form-control' />
              </div>
            </div>

            <div>
              <div className='path'>
                <label htmlFor=''>{INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_COMPANIES')}</label>
                <span
                  style={{ width: '310px' }}
                  onClick={() => setIsModalCompaniesActived(true)}
                  className='form-control'>
                  {companiesData === ''
                    ? INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_SELECT_COMPANIES')
                    : companiesData}
                </span>
              </div>

              <div className='path hide'>
                <label htmlFor=''>Tipo de crédito</label>
                <select name='' id='' className='custom-select'>
                  <option value='0'>Selecione um tipo</option>
                </select>
              </div>

              <div className='mt'>
                <span>
                  <ToggleSwitch
                    checked={ajustCredit}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAjustCredit(e.target.checked);
                        setAddCredit(false);
                      } else {
                        setAjustCredit(e.target.checked);
                        setAddCredit(true);
                      }
                    }}
                  />{' '}
                  {INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT_AJUST_CASH')}
                </span>

                <input
                  ref={refValueInsertedAjusted}
                  disabled={addCredit}
                  type='number'
                  min='0'
                  className='form-control'
                />
              </div>
            </div>
          </Container>
        )}

        {tabActived === 0 && (
          <FooterContainer>
            <button
              className='btn btn-danger'
              onClick={() => {
                history.push('/creditmanagement');
              }}>
              {INTL('PAGE.BLUEPRINT.BTN.Cancel')}
            </button>

            {
              isRequestApi ? (
                <button
                  className='btn btn-primary'
                  disabled={true}
                  onClick={() => {
                    //formRef.current?.click();
                    // handleSave();
                  }}>
                  {isRequestApi ? INTL('PERSON.PERSONS_TABLE_LOADING') : INTL('PAGE.BLUEPRINT.BTN.SAVE')}
                </button>
              ) : (
                <button
                  className='btn btn-primary'
                  disabled={isRead}
                  onClick={() => {
                    //formRef.current?.click();
                    handleSave();
                  }}>
                  {INTL('PAGE.BLUEPRINT.BTN.SAVE')}
                </button>
              )
            }

          </FooterContainer>
        )}

        {tabActived === 1 && <CreateAndEditCreditTypesManagemnt />}

        <ModalSelectGroupUsers
          isVisible={isModalPersonsActived}
          close={toggleModal}
          users={[userId]}
          fnUserSeelected={handleSelectedUser}
          fnSave={hanldeAddListUsers}
          title={INTL('MANAGEMENT_TYPES_CREDITS_GROUPS_OF_PEOPLE')}
        />

        <ModalSelectedCompanies
          isVisible={isModalCompaniesActived}
          close={() => {
            setIsModalCompaniesActived(false);
          }}
          users={[companiesId]}
          fnUserSeelected={handleSelectedGroup}
          fnSave={hanldeAddListCompanies}
          title='Empresas'
        />
      </CardBody>
    </Card>
  );
}
