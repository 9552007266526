import React from 'react';
import {BiInfoCircle} from 'react-icons/bi';

import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import * as S from './styles';
import {useIntl} from 'react-intl';

interface ILicenseCard {
  type: 'user' | 'device';

  title: string;
  subTitle: string;
  value: number;
  total: number;
  percentage: number;
}

export function LicenseCard(props: ILicenseCard) {
  const intl = useIntl();

  return (
    <S.Wrapper type={props.type}>
      <header>
        <h1>{props.title}</h1>
        <span className='sub-title'>
          <BiInfoCircle />
          {props.subTitle}
        </span>
      </header>

      <S.Body>
        <CircularProgressbarWithChildren value={props.percentage}>
          <h1>{`${props.percentage.toFixed(2)}%`}</h1>
          <span>{intl.formatMessage({id: 'IN_USE_LABEL'})}</span>
        </CircularProgressbarWithChildren>
      </S.Body>

      <S.Footer>
        {intl.formatMessage({id: 'TOTAL_LABEL'})}{' '}
        <h1>{`${props.value.toLocaleString()}/${props.total.toLocaleString()}`}</h1>
      </S.Footer>
    </S.Wrapper>
  );
}
