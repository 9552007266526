/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import {Dropdown} from 'react-bootstrap';
import {toAbsoluteUrl} from '../../../../_helpers';
import ReactTooltip from 'react-tooltip';
import {useLang, setLanguage} from '../../../../i18n';
import {DropdownTopbarItemToggler} from '../../../../_partials/dropdowns';
import {useIntl} from 'react-intl';

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/svg/flags/226-united-states.svg')
  },
  {
    lang: 'pt',
    name: 'Portuguese',
    flag: toAbsoluteUrl('/media/svg/flags/011-brazil.svg')
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/svg/flags/128-spain.svg')
  }
];

export function LanguageSelectorDropdown() {
  const lang = useLang();
  const intl = useIntl();
  const currentLanguage = languages.find((x) => x.lang == lang);

  return (
    <Dropdown>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id='dropdown-toggle'>
        <div data-tip data-for='tooltip' className='btn btn-icon btn-clean btn-dropdown btn-lg mr-1'>
          <img className='h-25x w-25px rounded' src={currentLanguage.flag} alt={currentLanguage.name} />
        </div>
        <ReactTooltip className='shadow' id='tooltip' type='light' effect='solid'>
          {intl.formatMessage({id: 'SELECT_LANGUAGE'})}
        </ReactTooltip>
      </Dropdown.Toggle>
      <Dropdown.Menu className='p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround' rootCloseEvent='mousedown'>
        <ul className='navi navi-hover py-4'>
          {languages.map((language, id) => (
            <li
              key={id}
              className={clsx('navi-item', {
                active: language.lang === currentLanguage.lang
              })}>
              <a href='#' onClick={() => setLanguage(language.lang)} className='navi-link'>
                <span className='symbol symbol-20 mr-3'>
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className='navi-text'>{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
