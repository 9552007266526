import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';

import axios from 'axios';

import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';

import './styles.css';
import { PainelHooks } from './hooks';
import { useIntl } from 'react-intl';
import { BluePrintOpenDoor, getRealTimeAccess, getRealTimeAccess2 } from 'src/services/bluePrintCrud';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { ContainerImg, InputContainer, Person, Title } from './styles';
import { RiDoorOpenFill } from 'react-icons/ri';
import { GoGraph } from 'react-icons/go';

// import {getRealTimeAccess, getRealTimeAccess2} from '../../../../services/dashboard.service';

const statusReportItensBlocks = [7, 11, 12, 15];

let counter = 0;

const historyString: string[] = [];
export function BluePrintView() {
  const [logs, setLogs] = useState<any>({
    deviceIds: [],
    logs: [],
    logsString: []
  });

  const intl = useIntl();
  //hooks
  const { history, setExt, devices, inputRef, TABLEDEVICES, plantImage, setPlantImage, points, handleUpdatePosition, removePoint, handleSave } = PainelHooks();

  const uploadImage = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  function loadImageInput({ target }: React.ChangeEvent<HTMLInputElement>) {

    // alert('Chamou');
    if (target && target.files && target.files.length > 0) {
      const file = new FileReader();

      file.onload = function (e) {
        //@ts-ignore
        setExt(target.files[0].type);
        setPlantImage(String(e.target?.result));
      };

      file.readAsDataURL(target.files[0]);
    }
  }

  async function compare() {
    const { data } = await getRealTimeAccess();

    const dataConvert = data.data.data.map((item: any) => {
      return {
        id: item.id,
        time: item.time,
        stringPros: JSON.stringify(item)
      };
    });


  }

  function statusReport(deviceID: number) {
    const log = logs.logs.find((log: any) => log.deviceId === deviceID);

    if (!log) return '';

    if (statusReportItensBlocks.includes(log.event)) {
      return 'point-open';
    }

    return 'point-block';
  }

  function Color(deviceID: number, card = false) {
    const log = logs.logs.find((log: any) => log.deviceId === deviceID);

    if (!log) return '#000';

    if (statusReportItensBlocks.includes(log.event)) {
      if (card) {
        return '1px 1px 6px green';
      }

      return '1px 1px 12px green';
    }

    if (card) {
      return '1px 1px 6px red';
    }

    return '1px 1px 12px red';
  }

  function ColorBorder(deviceID: number) {
    const log = logs.logs.find((log: any) => log.deviceId === deviceID);

    if (!log) return '1px solid #000';

    if (statusReportItensBlocks.includes(log.event)) {
      return '1px solid green !important';
    }

    return '1px solid red !important';
  }

  function HIDE() {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const elements = document.querySelectorAll('[data-notification]');

        elements.forEach((el: any, index) => {
          if (!el) return;

          el.style.left = '-3px';
          el.style.width = '34px';
          el.style.borderRadius = '50%';
          el.style.padding = ' 2px 0px';
          el.style.opacity = '0';

          if (el.parentElement) {
            el.parentElement.classList.remove('point-open');
            el.parentElement.classList.remove('point-block');
            el.parentElement.style.boxShadow = 'none';
          }
        });

        resolve('');
      }, 4000);
    }).then(() => {
      setTimeout(() => {
        setLogs(() => {
          setTimeout(helper, 1000);

          return {
            deviceIds: [],
            logs: [],
            logsString: []
          };
        });
      }, 500);
    });
  }

  function renderNotification(deviceId: number) {
    const logItem = logs.logs.find((item: any) => item.deviceId === deviceId);

    if (!logItem) return <></>;

    return (
      <Person
        data-notification={deviceId}
        style={{
          boxShadow: Color(deviceId, true)
        }}>
        {logItem.personAvatar ? (
          <img src={logItem.personAvatar} alt='' />
        ) : (
          <div className='MuiAvatar-root MuiAvatar-circle MuiAvatar-colorDefault' style={{ width: '30px', height: '30px' }}>
            <svg className='MuiSvgIcon-root MuiAvatar-fallback' focusable='false' viewBox='0 0 24 24' aria-hidden='true'>
              <path d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'></path>
            </svg>
          </div>
        )}

        <p>{!logItem.personName || logItem.personName === 'Not Identified' ? intl.formatMessage({ id: 'EVENTS.NOT_IDENTIFIED' }) : logItem.personName}</p>
      </Person>
    );
  }

  async function helper() {
    // const {data} = await axios.get('http://localhost:3333/data');
    const { data } = await getRealTimeAccess2();

    const dataConvert = data.data.data.reduce(
      (acc: any, logItem: any) => {
        // logItem.deviceId = 3;
        const jsonString = JSON.stringify(logItem);

        if (!historyString.includes(jsonString)) {
          acc.deviceIds.push(logItem.deviceId);
          acc.logs.push(logItem);
          acc.logsString.push(jsonString);

          historyString.push(jsonString);
          return acc;
        } else {
          return acc;
        }
      },
      {
        deviceIds: [],
        logs: [],
        logsString: []
      }
    );

    setLogs(() => {
      setTimeout(HIDE, 1000);
      return dataConvert;
    });
  }

  async function helper2() {
    const { data } = await axios.get('http://localhost:3333/data');
    // const {data} = await getRealTimeAccess();

    const dataConvert = data.data.data.reduce(
      (acc: any, logItem: any) => {
        // logItem.deviceId = 3;
        const jsonString = JSON.stringify(logItem);

        //@ts-ignore
        if (!historyString.includes(jsonString)) {
          acc.deviceIds.push(logItem.deviceId);
          acc.logs.push(logItem);
          acc.logsString.push(jsonString);
          //@ts-ignore
          historyString.push(jsonString);
          return acc;
        } else {
          return acc;
        }
      },
      {
        deviceIds: [],
        logs: [],
        logsString: []
      }
    );

    setLogs(() => {
      // setTimeout(() => {
      //   helper();
      // }, 1000);

      // return {
      //   deviceIds: [],
      //   logs: [],
      //   logsString: []
      // };

      return dataConvert;
    });
  }

  useEffect(() => {
    // setTimeout(helper, 3000);
    helper();
  }, []);

  // useEffect(() => {
  //   if (logs.logs.length > 0) {
  //     HIDE();
  //   }
  // }, [logs]);

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader icon={null} toolbar={null} className={null} labelRef={null} sticky={false} title={intl.formatMessage({ id: 'PAGE.BLUEPRINT.TITLE.VIEW' })} />

      <CardBody fit={null} fluid={null} className=''>
        <div id=''>
          <InputContainer style={{ display: 'none' }}>
            <input className='form-control' type='text' placeholder={intl.formatMessage({ id: 'PAGE.BLUEPRINT.LIST_COLUMN_DESCRIPTION' })} ref={inputRef} />

            <div style={{ display: 'flex', gap: '0.8rem', justifyContent: 'flex-end' }}>
              <button
                className='btn btn-light'
                onClick={() => {
                  history.push('/blueprint');
                }}>
                {intl.formatMessage({ id: 'PAGE.BLUEPRINT.BTN.Cancel' })}
              </button>
            </div>
          </InputContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Title>{inputRef.current?.value}</Title>

            <button
              className='btn btn-light'
              onClick={() => {
                history.push('/blueprint');
              }}>
              Voltar
            </button>
          </div>
          <ContainerImg>
            <section>
              <img src={plantImage} alt='' />
              <span />
              {points.map((point) => (

                <Draggable
                  key={point.id}
                  bounds='parent'
                  enableUserSelectHack
                  onStop={(e, data) => { }}
                  onStart={() => false}
                  position={{
                    x: point.x,
                    y: point.y
                  }}>
                  <div
                    className={logs.deviceIds.includes(point.idDevice) ? statusReport(point.idDevice) : ''}
                    onMouseEnter={() => {
                      //@ts-ignore
                      const btnClose = document.querySelector(`[data-id="P-painel-${point.id}"]`);
                      if (!btnClose) return;
                      //@ts-ignore
                      btnClose.style.display = 'flex';
                    }}
                    onMouseLeave={() => {
                      //@ts-ignore
                      const btnClose = document.querySelector(`[data-id="P-painel-${point.id}"]`);
                      if (!btnClose) return;
                      //@ts-ignore
                      btnClose.style.display = 'none';
                    }}
                    title={point.name}
                    style={{
                      width: '42px',
                      height: '42px',
                      borderRadius: '50%',
                      position: 'relative',
                      border: `1px solid #000`,
                      // border: `1px solid ${logs.deviceIds.includes(point.idDevice) ? ColorBorder(point.idDevice) : '#000'}`,
                      visibility: !point.actived ? 'hidden' : 'initial',
                      boxShadow: logs.deviceIds.includes(point.idDevice) ? Color(point.idDevice) : ''
                    }}>
                    <img
                      src={point.img}
                      alt=''
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                      }}
                    />

                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        position: 'absolute',
                        top: 0
                      }}
                    />

                    <main
                      data-id={`P-painel-${point.id}`}
                      style={{
                        background: 'white',
                        border: 'none',
                        top: '-35px',
                        left: '-52px',
                        position: 'absolute',
                        padding: '4px',
                        display: 'none',
                        justifyContent: 'center',
                        width: '50px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '#ccc',
                        flexDirection: 'column'
                      }}>
                      <button
                        title='Abrir porta'
                        style={{
                          border: 'none',
                          background: '#F6F7F6',
                          borderRadius: '5px'
                          // marginTop: '8px'
                        }}
                        onClick={async () => {
                          try {
                            await BluePrintOpenDoor(point.idDevice);
                            execToast('success', 'Porta aberta com sucesso', 3000);
                          } catch (err) {
                            execToast('error', 'Erro ao abrir porta', 3000);
                          }
                        }}
                        type='button'>
                        <RiDoorOpenFill size={22} color='#000' />
                      </button>

                      <button
                        title='Acessos do dispositivo'
                        style={{
                          border: 'none',
                          marginTop: '8px',
                          background: '#F4F5F4',
                          borderRadius: '5px'
                        }}
                        onClick={() => {
                          //@ts-ignore
                          window.deviceID = point.idDevice;

                          //@ts-ignore
                          window.devineName = point.name;
                          history.push(`/logs/devices`);
                        }}
                        type='button'>
                        <GoGraph size={22} color='#000' />
                      </button>
                    </main>
                    {logs.deviceIds.includes(point.idDevice) && renderNotification(point.idDevice)}
                    <span style={{
                      whiteSpace: "pre",
                      display: "block",
                      transform: "translateX(-50%)"
                    }}>{point.name}</span>
                  </div>
                </Draggable>
              ))}
            </section>

            {/* <section>
              <p>Dispositivos disponiveis</p>

              <ul>
                <li className='fixedd'>
                  <div>
                    <img src='' alt='' />
                  </div>
                  <div className='name'>Nome</div>
                  <div className='model'>Modelo</div>
                  <div className='output'>nº Saida</div>
                  <div className='area'>Area Destino</div>
                  <div className='action'>
                    <button title={intl.formatMessage({id: 'PAGE.BLUEPRINT.ADD_DEVICE_TO_PLANT'})} disabled={!plantImage} style={{cursor: plantImage ? 'pointer' : 'initial'}} className='btn btn-primary'>
                      <MdAddCircle size={16} />
                    </button>
                  </div>
                </li>

                <div className='divisor'>{TABLEDEVICES(devices)}</div>
              </ul>
            </section> */}
          </ContainerImg>
        </div>
        <canvas ref={canvasRef}></canvas>
      </CardBody>
    </Card>
  );
}
