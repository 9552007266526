import React, { useCallback, useEffect, useMemo, useState } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import SVG from 'react-inlinesvg';

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { Pagination } from 'src/_metronic/_partials/controls';
import { NoRecordsFoundMessage, PleaseWaitMessage, toAbsoluteUrl } from 'src/_metronic/_helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import { useIntl } from 'react-intl';
import { useLang } from 'src/_metronic/i18n';
import { Link } from 'react-router-dom';
import { GridListColumns } from './Utils/complements';
import { Checkbox } from '@material-ui/core';
import {
  ACTIVE_CREDIT_MANAGEMENT,
  DELETE_CREDIT_MANAGEMENT,
  GET_LIST_ALL_CREDIT_MANAGEMENT
} from 'src/services/manageCredtis';

import { Button, Col, Modal, Row } from 'react-bootstrap';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import LongMenu from 'src/_metronic/_partials/controls/MenuToolbarCard';
import { disableCreditTypes, enableCreditType, exportCreditType, getAllCreditTypes } from 'src/services/creditsCrud';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { BulkModal } from 'src/app/components/modals/Bulk';
import { bulkService } from 'src/services/bulk';
import { ActiveModal } from 'src/app/components/modals/Active';

let debounce: NodeJS.Timeout | null;

export function CreditType() {
  const intl = useIntl();
  const lang = useLang();
  const [statusActived, setStatusActived] = useState('1');
  const [tablePaginationOptions, setTablePaginationOptions] = useState({
    custom: true,
    totalSize: 0,
    sizePerPageList: window.sizePerPageList,
    sizePerPage: 10,
    page: 1
  });

  const [idsSelectedToRemoved, setIdsSelectedToRemoved] = useState<number[]>([])
  const [isModalRemoveAllItensSelectedOpen, setIsModalRemoveAllItensSelectedOpen] = useState(false)

  const [idsToReactived, setIdsToReactived] = useState<number[]>([])
  const [isModalReativePersons, setIsModalReativePersons] = useState(false)

  const [pageCurrent, setPageCurrent] = useState(1);
  const [sort, setSort] = useState<'desc' | 'asc'>('asc');
  const [search, setSearch] = useState('');

  const [creditTypesManagemntList, setCreditTypesManagemntList] = useState([]);

  const [showModalErase, setShowModalErase] = useState(false);
  const [modalDeleteIsShow, setModalDeleteIsShow] = useState(false);
  const [modalActivedIsShow, setModalActivedIsShow] = useState(false);
  const [dataModal, setDataModel] = useState({
    id: 0,
    name: ''
  });

  const [isRead, setIsRead] = useState(false);

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(16);
    const value = actionsIsDesatived(16);
    setIsRead(value);
  }, []);

  function handleDelete(id: number, name: string) {
    setDataModel({ id, name });
    setModalDeleteIsShow(true);
  }

  function handleReactive(id: number, name: string) {
    setDataModel({ id, name });
    setModalActivedIsShow(true);
  }

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  const columns = useMemo(() => {
    return GridListColumns(intl);
  }, [idsSelectedToRemoved, idsToReactived])

  function RenderAction(item: any) {


    function checkedRow(id: number) {
      const idIsSelected = idsSelectedToRemoved.find(item => item === id)
      if (!idIsSelected) {
        setIdsSelectedToRemoved([...idsSelectedToRemoved, id])

      } else {
        const ids = idsSelectedToRemoved.filter(item => item !== id)
        setIdsSelectedToRemoved(ids)
      }


    }

    function isChecked(id: number) {
      return idsSelectedToRemoved.includes(id)

    }

    function isCheckedActived(id: number) {
      return idsToReactived.includes(id)
    }

    function checkedRowActived(id: number) {
      const idIsSelected = idsToReactived.find(item => item === id)
      if (!idIsSelected) {
        setIdsToReactived([...idsToReactived, id])

      } else {
        const ids = idsToReactived.filter(item => item !== id)
        setIdsToReactived(ids)
      }


    }

    if (item.isEnabled) {

      return (
        <>
          <Checkbox defaultChecked checked={isChecked(item.id)} onChange={() => {
            checkedRow(item.id)
          }} />
          <Link
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            to={`/credittype/edit`}
            onClick={() => {
              localStorage.setItem('@CONTROLID@/credittype/edit', item.id);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              handleDelete(item.id, item.description);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <Checkbox defaultChecked checked={isCheckedActived(item.id)} onChange={() => {
          checkedRowActived(item.id)
        }} />
        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            handleReactive(item.id, item.description);
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }



  async function getList() {
    const { data } = await getAllCreditTypes({
      pageSize: tablePaginationOptions.sizePerPage,
      pageNumber: 1,
      status: +statusActived,
      sortOrder: 'desc',
      sortField: 'Description',
      value: search
    });

    // const tableValues = data.data.data.map((item: any) => {
    //   return {
    //     description: item.description,
    //     maxDailyConsumption: item.maxDailyConsumption,
    //     actions: RenderAction(item)
    //   };
    // });



    setTablePaginationOptions({
      ...tablePaginationOptions,
      totalSize: data.data.total,
      page: data.data.page
    });

    setCreditTypesManagemntList(data.data.data);
  }

  async function getListOnChange(sizePerPage: number) {
    // const { data } = await getAllCreditTypes({
    //   pageSize: sizePerPage,
    //   pageNumber: 1,
    //   status: +statusActived,
    //   sortOrder: 'desc',
    //   sortField: 'Description',
    //   value: search
    // });

    // // const tableValues = data.data.data.map((item: any) => {
    // //   return {
    // //     description: item.description,
    // //     maxDailyConsumption: item.maxDailyConsumption,
    // //     actions: RenderAction(item)
    // //   };
    // // });



    // setTablePaginationOptions({
    //   ...tablePaginationOptions,
    //   totalSize: data.data.total,
    //   sizePerPage: sizePerPage,
    //   page: data.data.page
    // });

    // setCreditTypesManagemntList(data.data.data);

    setTablePaginationOptions({
      ...tablePaginationOptions,
      sizePerPage
    })
  }

  const getListFilter = useCallback(async () => {

    const { data } = await getAllCreditTypes({
      pageSize: 10,
      pageNumber: pageCurrent,
      status: +statusActived,
      sortOrder: sort,
      sortField: 'Description',
      value: search
    });

    // const tableValues = data.data.data.map((item: any) => {
    //   return {
    //     description: item.description,
    //     maxDailyConsumption: item.maxDailyConsumption,
    //     actions: RenderAction(item)
    //   };
    // });

    setTablePaginationOptions({
      ...tablePaginationOptions,
      totalSize: data.data.total,
      page: data.data.page
    });

    setCreditTypesManagemntList(data.data.data);
  }, [pageCurrent, search, statusActived, sort, tablePaginationOptions.sizePerPage])

  // useEffect(() => {
  //   getList();
  // }, [statusActived, search]);

  useEffect(() => {
    getListFilter();
  }, [pageCurrent, sort, statusActived, search]);



  const headersCSVLogs = () => [
    intl.formatMessage({ id: 'LOGREGISTER.TABLE.COLUMN.NAME' }),
    intl.formatMessage({ id: 'CREDITS_TYPE.MAXDAILY' }).toUpperCase()
  ];

  const exportUrl = () => {
    return `/credit/type/export?pageSize=70000`;
  };

  const headerCSV = headersCSVLogs();

  function handleCulture(culture: string) {
    switch (culture) {
      case 'pt':
        return 'pt-br'
      case 'es':
        return 'es-es'
      case 'en':
        return 'en-us'
    }
  }

  const creditTypesManagemntListTable = useMemo(() => {
    return creditTypesManagemntList.map((item: any) => {

      return {
        description: item.description,
        maxDailyConsumption: item.maxDailyConsumption,
        actions: RenderAction(item)
      };
    })
  }, [idsSelectedToRemoved, idsToReactived, creditTypesManagemntList, tablePaginationOptions])




  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={INTL('PERSON.BUILDER.ADD_BUTTON')}
        linkAddTo='/credittype/create'
        title={INTL('ASIDE_MENU.CREDITS')}>
        <CardHeaderToolbar className={null}>
          <div style={{ width: '8vw' }}>
            <select value={statusActived} className='form-control' onChange={(e) => setStatusActived(e.target.value)}>
              <option value='2'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ALL')}</option>
              <option value='1'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ACTIVE')}</option>
              <option value='0'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.DESACTIVE')}</option>
            </select>
          </div>

          <div style={{ width: '8vw', marginLeft: '10px' }}>
            <input
              onChange={({ target }) => {
                if (debounce) clearTimeout(debounce);
                debounce = setTimeout(() => {
                  setSearch(target.value);
                }, 500);
              }}
              className='form-control'
              type='text'
              placeholder={INTL('PAGE.BLUEPRINT.LIST_INPUT.SEARCH')}
            />
          </div>

          <div style={{ display: 'flex', gap: '5px' }}>
            <Link
              title={INTL('CREDITS_TYPE.NEW_CREDIT')}
              type='button'
              className='btn btn-primary ml-5 mr-2'
              onClick={() => { }}
              to='/credittype/create'>
              {INTL('CREDITS_TYPE.NEW_CREDIT')}
            </Link>

            {
              idsSelectedToRemoved.length > 0 && (<button
                title={intl.formatMessage({ id: 'BULK.IN.INACTIVATE' })}
                type='button'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  height: '38px',
                  border: 'none',
                  borderRadius: '6px',
                  padding: '0px 16px 0px 16px'
                }}
                onClick={() => {
                  setIsModalRemoveAllItensSelectedOpen(true)
                }}
                className={idsSelectedToRemoved.length === 0 ? 'btn-disabled ml-5 mr-2' : 'btn-danger ml-5 mr-2'}
              >

                {intl.formatMessage({ id: 'BULK.IN.INACTIVATE' })}
              </button>)
            }

            {
              idsToReactived.length > 0 && (<button
                title={intl.formatMessage({ id: 'ACTIVATE.IN.BULK' })}
                type='button'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  height: '38px',
                  border: 'none',
                  borderRadius: '6px',
                  padding: '0px 16px 0px 16px'
                }}
                onClick={() => {
                  setIsModalReativePersons(true)
                }}
                className={idsToReactived.length === 0 ? 'btn-disabled ml-5 mr-2' : 'btn-success ml-5 mr-2'}
              >
                {intl.formatMessage({ id: 'ACTIVATE.IN.BULK' })}
              </button>)
            }
          </div>

          <div className='d-flex mx-4'>
            <LongMenu
              filtros={{
                pageSize: tablePaginationOptions.sizePerPage,
                pageNumber: tablePaginationOptions.page,
                status: statusActived,
                sortOrder: sort,
                sortField: 'Description',
                culture: handleCulture(lang)
              }}
              totalCount={tablePaginationOptions.totalSize}
              linkApi={exportUrl()}
              headers={headerCSV}
              nomeArquivo={intl.formatMessage({
                id: 'LOGREGISTER.PEOPLE'
              })}
            />
          </div>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody fit={null} fluid={null} className={null}>
        <PaginationProvider pagination={paginationFactory(tablePaginationOptions)}>
          {({ paginationProps, paginationTableProps }: any) => {
            return (
              <Pagination isLoading={false} paginationProps={paginationProps}>
                <BootstrapTable
                  onTableChange={(type, props) => {


                    if (type === 'pagination') {
                      setPageCurrent(props.page);
                    }

                    if (type === 'sort') {
                      setSort(props.sortOrder);
                    }
                    // if (type == 'sort') {
                    //   order.setSortOrder(props.sortOrder);
                    // }

                    // if (!props.page) return;
                    // page.setPage(props.page);
                    // page.setSizePerPage(props.sizePerPage);
                    // setTablePaginationOptions({
                    //   ...tablePaginationOptions,
                    //   sizePerPage: props.sizePerPage,
                    //   page: props.page
                    // });
                    getListOnChange(props.sizePerPage)

                  }}
                  // defaultSorted={[defaultSortedColumn]}
                  wrapperClasses='table-responsive'
                  bordered={false}
                  classes='table table-head-custom table-vertical-center overflow-hidden'
                  remote
                  bootstrap4
                  keyField='id'
                  data={creditTypesManagemntListTable}
                  columns={columns}
                  // columns={tableColumns(lang, intl)}
                  {...paginationTableProps}>
                  <PleaseWaitMessage entities={creditTypesManagemntListTable} />
                  <NoRecordsFoundMessage entities={[creditTypesManagemntListTable]} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </CardBody>

      <Modal show={modalDeleteIsShow} onHide={() => setModalDeleteIsShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {INTL('TOAST.DELETE_BUTTON')} : {dataModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{INTL('TOAST.REMOVE.CREDITS_TYPE')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setModalDeleteIsShow(false);
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>
          <div style={{ width: '88px' }} />
          <Button
            variant='outline-danger'
            onClick={() => {
              setShowModalErase(true);
              setModalDeleteIsShow(false);
            }}>
            {INTL('PERMANENTLY_DELETE')}
          </Button>

          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              // if (isRead) return;
              setModalDeleteIsShow(false);
              await disableCreditTypes(dataModal.id);
              getList();
              execToast('success', INTL('TOAST.CREDITS_TYPE.SUCCESSFULL_INACTIVATED'), 3000);
            }}>
            {INTL('TOAST.DELETE_BUTTON')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModalErase} onHide={() => setShowModalErase(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: '#F64E60' }}>{`${dataModal.name}: ${INTL(
            'ARE_YOU_SURE_YOU_WANT_TO_PERMANENTLY_DELETE_IT'
          )}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{intl.formatMessage({ id: 'THIS_ACTION_CANNOT_BE_UNDONE' })} </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setShowModalErase(false);
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>

          <div style={{ width: '34px' }} />
          <Button
            disabled={isRead}
            variant='outline-danger'
            onClick={async () => {
              // if (isRead) return;
              setModalDeleteIsShow(false);
              setShowModalErase(false);
              await disableCreditTypes(dataModal.id);
              getList();
              execToast('success', INTL('TOAST.CREDITS_TYPE.SUCCESSFULL_INACTIVATED'), 3000);
            }}>
            {intl.formatMessage({ id: 'INACTIVATE_ONLY' })}
          </Button>

          <Button
            variant='danger'
            disabled={isRead}
            onClick={async () => {
              // if (isRead) return;
              setModalDeleteIsShow(false);
              setShowModalErase(false);
              await disableCreditTypes(dataModal.id, true);
              getList();
              execToast('success', INTL('TOAST.PERMANENTLY_DELETED.SUCCESS'), 3000);
            }}>
            {INTL('PERMANENTLY_DELETE')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalActivedIsShow} onHide={() => setModalActivedIsShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {INTL('TOAST.ENABLE')} : {dataModal.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{INTL('TOAST.ENABLE.CREDITS_TYPE')}</Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setModalActivedIsShow(false);
            }}>
            {INTL('PERSON.NEWPERSON_CANCEL_BUTTON')}
          </Button>
          <Button
            variant='primary'
            // disabled={isRead}
            onClick={async () => {
              // if (isRead) return;
              setModalActivedIsShow(false);
              await enableCreditType(dataModal.id);
              getList();
              execToast('success', INTL('OPERATOR_ROLE_.TOAST_ATIVE'), 3000);
            }}>
            {INTL('TOAST.ENABLE')}
          </Button>
        </Modal.Footer>
      </Modal>

      <BulkModal
        titles={
          {
            toggle: intl.formatMessage({ id: 'TOGGLE.MSG.DELETE.BULK.TYPECREDIT' }),
            headerInative: intl.formatMessage({ id: 'INACTIVATE.HEADER.DELETE.BULK.TYPECREDIT' }),
            headerDelete: intl.formatMessage({ id: 'MODAL.MSG.DELETE.BULK.TYPECREDIT' }),
            bodyInative: intl.formatMessage({ id: 'MODAL.MSG.INACTIVATE.BULK' }),
            bodyDelete: intl.formatMessage({ id: 'CONFIRM.MSG.DELETE.BULK' })
          }
        }

        toastMessage={
          {
            error: intl.formatMessage({ id: 'MODAL.DELETE.TOAST.ERROR' }),
            success: intl.formatMessage({ id: 'MODAL.DELETE.TOAST.SUCCESS' })
          }
        }

        buttons={{
          cancel: intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' }),
          delete: intl.formatMessage({ id: 'TOAST.DELETE' }),
          inative: intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })
        }}
        data={{
          idsToTemoved: idsSelectedToRemoved,
          resetIds: () => setIdsSelectedToRemoved([]),
          save: async (ids: number[], removed: boolean) => {
            await bulkService.removeTypeCredit(ids, removed)
          },
          reload: () => {
            // clearFilter()
            getList()
          }
        }}
        isVisibleModal={isModalRemoveAllItensSelectedOpen}
        closeModal={() => setIsModalRemoveAllItensSelectedOpen(false)}
      />

      <ActiveModal
        titles={
          {
            headerInative: intl.formatMessage({ id: 'MODAL.ACTIVED.HEADER.TYPECREDI' }),
            bodyInative: intl.formatMessage({ id: 'MODAL.ACTIVED.MSG' }),
          }
        }

        toastMessage={
          {
            error: intl.formatMessage({ id: 'MODAL.TOAST.ERROR' }),
            success: intl.formatMessage({ id: 'MODAL.TOAST.SUCCESS' })
          }
        }

        buttons={{
          cancel: intl.formatMessage({ id: 'PAGE.BLUEPRINT.BTN.Cancel' }),
          inative: intl.formatMessage({ id: 'TOAST.ENABLE' })
        }}
        data={{
          idsToTemoved: idsToReactived,
          resetIds: () => setIdsToReactived([]),
          save: async (ids: number[]) => {
            await bulkService.activedTypeCredit(ids)
          },
          reload: () => {
            getList()
          }
        }}
        isVisibleModal={isModalReativePersons}
        closeModal={() => setIsModalReativePersons(false)}
      />
    </Card>
  );
}
