export function createVariables() {
  window.controlID = {
    devices: {
      duration1: 0,
      duration2: 0,
      duration3: 0,
      duration4: 0,
      outputType1: 0,
      outputType2: 0,
      outputType3: 0,
      outputType4: 0,
      faceidentry: 1,
      shouldDetectMask1: false,
      shouldDetectMask2: false,
      isMaskRequired1: false,
      isMaskRequired2: false,
      faceDetectionDistance1: 5,
      faceDetectionDistance2: 5
    },
    personModule: {
      cards: [],
      qrcode: {},
      cardRemote: {}
    },
    modules: {
      devices: {
        mode_operation: ''
      }
    }
  };
}
