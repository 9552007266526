import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLang } from 'src/_metronic/i18n';
import { Link } from 'react-router-dom';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { Card, CardBody, CardHeader, CardHeaderToolbar } from 'src/_metronic/_partials/controls';
import LongMenu from 'src/_metronic/_partials/controls/MenuToolbarCard';


//@ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { useParkingProvider } from '../Context/ParkingContext';

const selectStyle = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    border: '1px solid #E4E6EF',
    '&:hover': {
      cursor: 'pointer'
    }
  })
};
let debounce: NodeJS.Timeout | null;

interface HeaderProps {
  idsSelectedToRemoved: number[],
  setIsModalRemoveAllItensSelectedOpen(value: boolean): void,
  idsToReactived: number[],
  setIsModalReativePersons(value: boolean): void
}

export function Header({ idsSelectedToRemoved, setIsModalRemoveAllItensSelectedOpen, idsToReactived, setIsModalReativePersons }: HeaderProps) {
  const [statusActived, setStatusActived] = useState('1');
  const [search, setSearch] = useState('');

  const { fn: { load, alterSTatus } } = useParkingProvider()

  const intl = useIntl();
  const lang = useLang();

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  const exportUrl = () => {
    return `/parkingLot/export?pageSize=90000`;
  };

  const headersCSVLogs = () => [
    intl.formatMessage({ id: 'PARKING_TABLE__ONE' }),
    intl.formatMessage({ id: 'PARKING_TABLE__TWO' }).toUpperCase(),
  ];

  const headerCSV = headersCSVLogs();

  function handleCulture(culture: string) {
    switch (culture) {
      case 'pt':
        return 'pt-br'
      case 'es':
        return 'es-es'
      case 'en':
        return 'en-us'
    }
  }


  return (
    <CardHeader
      icon={null}
      toolbar={null}
      className={null}
      labelRef={null}
      sticky={false}
      linkAddTitle={INTL('PERSON.BUILDER.ADD_BUTTON')}
      linkAddTo='/parkingregister/manager'
      title={INTL('ASIDE.MENU.ITEM.PARKING')}>
      {/* <CardHeader   linkAddTitle={INTL('PERSON.BUILDER.ADD_BUTTON')} linkAddTo='/creditmanagement/create' title={INTL('ASIDE_MANAGEMENT_TYPES_CREDITS')} /> */}
      <CardHeaderToolbar className={null}>
        <div style={{ width: '8vw' }}>
          <select value={statusActived} className='form-control' onChange={(e) => {
            setStatusActived(e.target.value)

            let statusNumber = +e.target.value as 1 | 2 | 0
            alterSTatus(statusNumber)
          }}>
            <option value='2'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ALL')}</option>
            <option value='1'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.ACTIVE')}</option>
            <option value='0'>{INTL('PAGE.BLUEPRINT.LIST_STATUS.DESACTIVE')}</option>
          </select>
        </div>

        <div style={{ width: '8vw', marginLeft: '10px' }}>
          <input
            onChange={({ target }) => {
              if (debounce) clearTimeout(debounce);
              debounce = setTimeout(() => {
                setSearch(target.value);
                load(target.value)
              }, 500);
            }}
            className='form-control'
            type='text'
            placeholder={INTL('PAGE.BLUEPRINT.LIST_INPUT.SEARCH')}
          />
        </div>

        <div style={{
          display: 'flex'
        }}>


          <Link
            title={INTL('MANAGEMENT_TYPES_CREDITS_BATCH_CREDIT')}
            type='button'
            className='btn btn-primary ml-5 mr-2'
            onClick={() => { }}
            to='/parkingregister/manager'>
            {INTL('PARKING_NEW_REGISTER')}

          </Link>

          {
            idsSelectedToRemoved.length > 0 && (<button
              title={intl.formatMessage({ id: 'BULK.IN.INACTIVATE' })}
              type='button'
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                height: '38px',
                border: 'none',
                borderRadius: '6px',
                padding: '0px 16px 0px 16px'
              }}
              onClick={() => {
                setIsModalRemoveAllItensSelectedOpen(true)
              }}
              className={idsSelectedToRemoved.length === 0 ? 'btn-disabled ml-5 mr-2' : 'btn-danger ml-5 mr-2'}
            >

              {intl.formatMessage({ id: 'BULK.IN.INACTIVATE' })}
            </button>)
          }

          {
            idsToReactived.length > 0 && (<button
              title={intl.formatMessage({ id: 'ACTIVATE.IN.BULK' })}
              type='button'
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                height: '38px',
                border: 'none',
                borderRadius: '6px',
                padding: '0px 16px 0px 16px'
              }}
              onClick={() => {
                setIsModalReativePersons(true)
              }}
              className={idsToReactived.length === 0 ? 'btn-disabled ml-5 mr-2' : 'btn-success ml-5 mr-2'}
            >
              {intl.formatMessage({ id: 'ACTIVATE.IN.BULK' })}
            </button>)
          }
        </div>

        <Row className='mr-5' style={{ paddingRight: 5 }}>
          <Col>
            <LongMenu
              invert={true}
              filtros={{
                pageNumber: 1,
                status: statusActived,
                sortOrder: 'asc',
                sortField: 'Id',
                culture: handleCulture(lang)
              }}
              // totalCount={10}
              linkApi={exportUrl()}
              headers={headerCSV}
              nomeArquivo={intl.formatMessage({
                id: 'ASIDE.MENU.ITEM.PARKING'
              })}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',

                  padding: '0px 17px'
                }}></div>
            </LongMenu>
          </Col>
        </Row>
      </CardHeaderToolbar>
    </CardHeader>
  );
}
