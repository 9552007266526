import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHtmlClassService} from '../../_core/MetronicLayout';
import {useHistory} from 'react-router-dom'
import { toAbsoluteUrl } from 'src/_metronic/_helpers';

export function Footer() {
  const history = useHistory()
  const {authToken: user} = useSelector((state) => state.auth);
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const intl = useIntl()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true)
    };
  }, [uiService]);


  function goRelease(){

    history.push('/releases')
  }

  return (
    <div className={`footer bg-white py-4 d-flex margin-card2 flex-lg-column  ${layoutProps.footerClasses}`} id='kt_footer'>
      <div className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}>
        <div className='text-dark order-2 order-md-1 mt-5 row'>
          <span className='text-muted font-weight-bold mr-2'>{today.toString()}</span> &copy;{' '}
          <a href='https://www.controlid.com.br/' target='_blank' rel='noopener noreferrer' className='text-dark-75 text-hover-primary pl-1'>
            Control iD
          </a>
          <p className='text-dark-75 ml-3' style={{cursor:"pointer"}} onClick={goRelease} title="Acessar pagina de releases">{process.env.REACT_APP_VERSION}</p>
          <p className='text-dark-75 ml-3'>{intl.formatMessage({id: "FOOTER.ITEM_CLIENT"})}: {user.tenant}</p>
        </div>
        <div className='nav nav-dark order-1 order-md-2'>
          <a href={toAbsoluteUrl("media/2022.12.02 - Acordo de Licença Para Usuário Final (EULA)_v2_clean.pdf")} target='_blank' rel='noopener noreferrer' className='nav-link pr-3 pl-0'>
            <FormattedMessage id={'FOOTER.ITEM_USE_LICENSE'} />
          </a>
          
          <a href={toAbsoluteUrl("media/politicaPrivacidade.pdf")} target='_blank' rel="noopener noreferrer" className='nav-link px-3'>
                    <FormattedMessage id={'FOOTER.ITEM_PRIVACY'} />
                  </a>
        </div>
      </div>
    </div>
  );
}
