import React, { useEffect, useState } from 'react';
import { Table } from './pages/Table';
import { Create } from './pages/Create';
import { useIntl } from 'react-intl';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { findGroups, getAllPersons } from 'src/services/personsCrud';
import { createNewPark, editParkingSpace, getParkingSpaceById, newParkingSpace, editPark } from 'src/services/parkingService';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { AsyncPaginate } from 'react-select-async-paginate';
import { standardTranslation } from 'src/utils/standardTranslation';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';
import { useHistory } from 'react-router-dom';
interface PROPS {
  idLot: number;
  name: string;
  addSpacing(value: any): void;
  deleteCard(value: number): void;
  dataListSpacings: any[];
  loadSpaces(id: number): void;
  spaceAvailable?: any;
  constrolSpaceAvailable?: any;
}
export function ParkingSpaces({ idLot, name, addSpacing, dataListSpacings, loadSpaces, deleteCard, spaceAvailable, constrolSpaceAvailable }: PROPS) {


  const [viewControl, setViewControl] = useState('table');
  const history = useHistory()

  const [isProgressRequest, setIsProgressRequest] = useState(false);

  const [isModalSpaces, setIsModalSpaces] = useState(false);
  const intl = useIntl();

  function handlerView(value: string) {
    // setViewControl(value)
    setIsModalSpaces(!isModalSpaces);
  }

  const isEditing = false;
  const [id, setId] = useState(0);
  const [index, setIndex] = useState<any>(undefined);
  const [type, setType] = useState('3');
  const [idSpce, setIdSpace] = useState(0);
  const [nameParking, setNameParking] = useState('');
  const [numberSpaces, setNumberSpaces] = useState(1);
  const [vagasPCD, setVagasPCD] = useState(0);
  const [person, setPerson] = useState({
    label: '',
    value: 0
  });
  const [group, setGroup] = useState({
    label: '',
    value: 0
  });

  const [isPCD, setIsPCD] = useState(false);

  const [isRead, setIsRead] = useState(false);

  const { redirectView, actionsIsDesatived } = usePermisssion();

  useEffect(() => {
    redirectView(20);
    const value = actionsIsDesatived(20);
    setIsRead(value);
  }, []);

  function INTL(value: string) {
    return intl.formatMessage({ id: value });
  }

  async function handleSave_() {
    let typeL = '';

    let space = numberSpaces.toString();

    if (space[0] === '0') {
      space = space.substring(1, space.length);
    }

    if (type === '1') {
      typeL = 'Personal';
    }
    if (type === '2') {
      typeL = 'Enterprise';
    }
    if (type === '3') {
      typeL = 'Common';
    }

    try {
      const payload = {
        'parkingLotId': idLot,
        'description': name,
        'capacity': space,
        'type': typeL,
        'pcdCapacity': vagasPCD
      };

      // await newParkingSpace(payload)

      // execToast('success', intl.formatMessage({ id: 'Vaga criada com sucesso' }), 3000);
      // fn('table')
    } catch (err) {
      if (!err.response.data.message) {
        execToast('error', intl.formatMessage({ id: 'UNABLE_TO_SAVE_VACANCY' }), 3000);
        return;
      }

      // console.dir(err)

      // if(err.response.data.message === '442'){
      //   execToast('error','Já existe um estacionamento com esse nome', 3000);
      //   return
      // }
      execToast('error', intl.formatMessage({ id: 'EXCESS_NUMBER_OF_VACANCIES' }), 3000);
    }
  }

  async function handleEdit() {
    let typeL = '';

    let space = numberSpaces.toString();
    if (space[0] === '0') {
      space = space.substring(1, space.length);
    }

    if (type === '1') {
      typeL = 'Personal';
    }
    if (type === '2') {
      typeL = 'Enterprise';
    }
    if (type === '3') {
      typeL = 'Common';
    }

    try {
      const payload = {
        'id': idSpce,
        'parkingLotId': idLot,
        'description': name,
        'capacity': space,
        'type': typeL,
        'pcdCapacity': vagasPCD
      };

      await editParkingSpace(payload);

      execToast('success', intl.formatMessage({ id: 'VACANCY_EDITED_SUCCESSFULLY' }), 3000);
      // fn('table')
    } catch (err) {
      if (!err.response.data.message) {
        execToast('error', intl.formatMessage({ id: 'UNABLE_TO_EDIT_VACANCY' }), 3000);
        return;
      }
      execToast('error', intl.formatMessage({ id: 'EXCESS_NUMBER_OF_VACANCIES' }), 3000);
    }
  }

  async function loadOneSpace(id: number) {
    const {
      data: { data }
    } = await getParkingSpaceById(id);


    // setName(data.description)
    setNumberSpaces(data.capacity);
    setVagasPCD(data.pcdCapacity);

    if (data.type === 'Common') {
      setType('3');
    }

    if (data.type === 'Enterprise') {
      setType('2');
    }

    if (data.type === 'Personal') {
      setType('1');
    }
  }

  const getPeople = async (value: string) => {
    const {
      data: {
        data: { data: people, pages }
      }
    } = await getAllPersons({
      pageSize: 100,
      pageNumber: 1,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name',
      personType: 'Person'
    });

    return {
      options: people.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: false
    };
  };

  const selectStyle = {
    control: (styles: any) => ({
      ...styles,
      border: '1px solid #E4E6EF',
      '&:hover': {
        cursor: 'pointer'
      }
    })
  };

  const getGroupsAndBuniss = async (value: string) => {
    const {
      data: {
        data: { data: people, pages }
      }
    } = await findGroups({
      groupType: 1,
      status: 1,
      sortOrder: 'asc',
      sortField: 'Description',
      pageNumber: 1,
      pageSize: 100,
      value
    });

    const {
      data: {
        data: { data: buniss }
      }
    } = await findGroups({
      groupType: 4,
      status: 1,
      sortOrder: 'asc',
      sortField: 'Description',
      pageNumber: 1,
      pageSize: 100,
      value
    });

    return {
      options: [
        ...people.map((el: any) => ({ value: el.id, label: standardTranslation(el.description, intl) })),
        ...buniss.map((el: any) => ({ value: el.id, label: standardTranslation(el.description, intl) }))
      ],
      hasMore: false
    };
  };

  async function handleSave___() {
    const payload: any = {
      'parkingLotId': idLot,
      'description': nameParking.trim(),
      'capacity': numberSpaces,
      'type': type,
      'pcdCapacity': vagasPCD,
      'personId': person.value,
      'groupId': group.value
    };

    if (!payload.groupId) {
      delete payload.groupId;
    }

    if (!payload.personId) {
      delete payload.personId;
    }

    if (!payload.description) {
      execToast('error', intl.formatMessage({ id: 'SPACE_NAME_CANNOT_BE_EMPTY' }), 3000);
      return;
    }

    if (!payload.capacity) {
      execToast('error', intl.formatMessage({ id: 'TOTAL_NUMBER_OF_SEATS_CANNOT_BE0' }), 3000);
      return;
    }

    if (payload.capacity < payload.pcdCapacity) {
      execToast(
        'error',
        intl.formatMessage({ id: 'NUMBER_OF_VACANCIES_FOR_PCD_CANNOT_BE_GREATER_THAN_THE_TOTAL_NUMBER_OF_VACANCIES' }),
        3000
      );
      return;
    }
    setIsProgressRequest(true);

    if (idSpce) {
      payload.id = idSpce;

      try {
        // await editParkingSpace(payload)
        execToast('success', intl.formatMessage({ id: 'VACANCY_EDITED_SUCCESSFULLY' }), 3000);
        setIsModalSpaces(false);
        loadSpaces(idLot);
        setIsProgressRequest(false);
      } catch (e) {
        setIsProgressRequest(false);
        console.dir(e);

        if (e.response.status == 422) {
          execToast('error', intl.formatMessage({ id: 'EXCESS_NUMBER_OF_VACANCIES' }), 3000);
        }
      }

      return;
    }

    if (idLot) {
      let typeL = '';

      let space = numberSpaces.toString();

      if (space[0] === '0') {
        payload.capacity = space.substring(1, space.length);
      }

      if (type === '1') {
        typeL = 'Personal';
      }
      if (type === '2') {
        typeL = 'Enterprise';
      }
      if (type === '3') {
        typeL = 'Common';
      }

      try {
        await newParkingSpace(payload);

        execToast('success', intl.formatMessage({ id: 'VACANCY_CREATED_SUCCESSFULLY' }), 3000);
        setIsModalSpaces(false);
        setIsProgressRequest(false);
        loadSpaces(idLot);
      } catch (err) {
        setIsProgressRequest(false);
        if (!err.response.data.message) {
          execToast('error', intl.formatMessage({ id: 'UNABLE_TO_SAVE_VACANCY' }), 3000);
          return;
        }

        console.dir(err);

        // if(err.response.data.message === '442'){
        //   execToast('error','Já existe um estacionamento com esse nome', 3000);
        //   return
        // }
        execToast('error', intl.formatMessage({ id: 'EXCESS_NUMBER_OF_VACANCIES' }), 3000);
      }
    } else {
      addSpacing(payload);
      setIsModalSpaces(false);
    }
  }

  function validation(payload: any) {
    if (!payload.groupId) {
      delete payload.groupId;
    }

    if (!payload.personId) {
      delete payload.personId;
    }

    if (!payload.description) {
      execToast('error', intl.formatMessage({ id: 'SPACE_NAME_CANNOT_BE_EMPTY' }), 3000);
      return true;
    }

    if (!payload.capacity) {
      execToast('error', intl.formatMessage({ id: 'TOTAL_NUMBER_OF_SEATS_CANNOT_BE0' }), 3000);
      return true;
    }

    if (payload.capacity < payload.pcdCapacity) {
      execToast(
        'error',
        intl.formatMessage({ id: 'NUMBER_OF_VACANCIES_FOR_PCD_CANNOT_BE_GREATER_THAN_THE_TOTAL_NUMBER_OF_VACANCIES' }),
        3000
      );
      return true;
    }

    return false;
  }

  async function handleSave() {
    const payload: any = {
      'parkingLotId': idLot,
      'description': nameParking.trim(),
      'capacity': numberSpaces,
      'type': type,
      'pcdCapacity': isPCD ? numberSpaces : 0,
      'personId': person.value,
      'groupId': group.value,
      'id': id,
      'index': index
    };


    if (validation(payload)) return;

    if (idLot !== 0) {
      const editingRemove = dataListSpacings.filter((item: any) => item.id !== id)
      try {

        await editPark({
          // capacity: 0,
          description: name.trim(),
          unlimitedCapacity: constrolSpaceAvailable,
          parkingSpaces: [...editingRemove, payload],
          id: idLot
        })

        // sessionStorage.removeItem('@CONTROLID@/parking/edit');

        execToast('success', intl.formatMessage({ id: 'PARKING_SAVE_SUCCESS' }), 3000);
        setIsModalSpaces(false);
        setIsProgressRequest(false);
        loadSpaces(idLot);
        // history.goBack()
      } catch (err) {


        if (err.response.data.message === '442') {
          execToast('error', intl.formatMessage({ id: 'PARKING.A_PARKING_LOT_WITH_THAT_NAME_ALREADY_EXISTS' }), 3000);
          return
        }

        execToast('error', intl.formatMessage({ id: 'PARKING_ADD_ERROR' }), 3000);
      } finally {

        addSpacing(payload);
        setIsModalSpaces(false);
      }
    } else {
      addSpacing(payload);
      setIsModalSpaces(false);
    }


  }

  function handleSetValuesInModal(item: any) {
    setIsModalSpaces(true);


    let typeL = '';
    if (item.type === 'Personal') {
      typeL = '1';
    }
    if (item.type === 'Enterprise') {
      typeL = '2';
    }
    if (item.type === 'Common') {
      typeL = '3';
    }

    setType(typeL || item.type);
    setNameParking(item.description);
    setNumberSpaces(item.capacity);
    setVagasPCD(item.pcdCapacity);

    if (item.pcdCapacity) {
      setIsPCD(true);
    } else {
      setIsPCD(false);
    }
    setId(item.id);
    setIndex(item.index);


    setPerson({
      value: item.personId || 0,
      label: item.personName || ''
    });

    setGroup({
      value: item.groupId || 0,
      label: item.groupName || ''
    });

    setIdSpace(item.index);
  }

  return (
    <>
      {viewControl === 'table' && (
        <div>
          <button
            type='button'
            className='btn-elevate btn btn-primary'
            onClick={() => {
              setIdSpace(0);
              setType('3');
              setNameParking('');
              setNumberSpaces(0);
              setVagasPCD(0);
              setId(0);

              setIndex(undefined);

              setIsPCD(false);

              setIsModalSpaces(true);
            }}>
            {intl.formatMessage({ id: 'TAB_SPACE_CREATE' })}
          </button>

          <Table
            idLot={idLot}
            name={name}
            fn={handlerView}
            data={dataListSpacings}
            fnSetData={handleSetValuesInModal}
            reload={loadSpaces}
            deleteCard={deleteCard}
          />
        </div>
      )}

      <Modal
        show={isModalSpaces}
        onHide={() => {
          setIsModalSpaces(!isModalSpaces);

          setPerson({
            label: '',
            value: 0
          });

          setGroup({
            label: '',
            value: 0
          });
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {index !== undefined
              ? intl.formatMessage({ id: 'TAB_SPACE_EDIT' })
              : intl.formatMessage({ id: 'TAB_SPACE_CREATE' })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <label htmlFor=''>{intl.formatMessage({ id: 'TAB_SPACE.SPACE_NAME' })}</label>
              <input
                className='form-control'
                type='text'
                value={nameParking}
                onChange={(e) => {
                  setNameParking(e.target.value);
                }}
              />
            </div>

            <div
              style={{
                marginTop: '5px'
              }}>
              <label htmlFor=''>{intl.formatMessage({ id: 'PARKING_SPACES' })}</label>
              <input
                className='form-control'
                type='text'
                value={numberSpaces}
                onChange={(e) => {
                  setNumberSpaces(/^(?:\d*\.?\d+|)$/.test(e.target.value) ? +e.target.value : numberSpaces);

                  // setNumberSpaces(+space)
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '6px',
                marginTop: '15px'
              }}>
              <input
                style={{ marginBottom: '0.5rem' }}
                type='checkbox'
                checked={isPCD}
                onChange={(e) => {
                  setIsPCD(e.target.checked);
                }}
              />
              <label htmlFor=''>PCD</label>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: '5px',
                marginBottom: '5px'
              }}>
              <label htmlFor=''>{intl.formatMessage({ id: 'PARKING_TYPE' })}</label>
              <select
                name=''
                id=''
                className='form-control'
                value={type}
                onChange={(e) => {
                  setType(e.target.value);

                  if (e.target.value === '3') {
                    setPerson({
                      value: 0,
                      label: ''
                    });

                    setGroup({
                      value: 0,
                      label: ''
                    });
                  }
                }}>
                <option value='1'>{intl.formatMessage({ id: 'PERSON.BUILDER.PERSONAL' })}</option>
                <option value='2'>{intl.formatMessage({ id: 'CREATE_VEHICLE_COMPANY' })}</option>
                <option value='3'>{intl.formatMessage({ id: 'PARKING_COMMON' })}</option>
              </select>
            </div>

            {type === '2' && (
              <>
                <div
                  style={{
                    width: '100%'
                  }}>
                  <label className={'form-label'}>{intl.formatMessage({ id: 'PERSON.BUILDER.ITEM.COMPANIES' })}</label>

                  <AsyncPaginate
                    loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
                    noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
                    debounceTimeout={500}
                    styles={selectStyle}
                    closeMenuOnSelect={true}
                    placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
                    loadOptions={getGroupsAndBuniss}
                    loadOptionsOnMenuOpen
                    value={group}
                    onChange={(values: any) => {
                      setGroup(values);
                      setPerson({ label: '', value: 0 });

                      //const ids = values ? [...values.map(({value}: any) => value)] : [];

                      // setIdsVisitsActived(ids);
                      // setQueryParams((prev: any) => {
                      //   return {...prev, personsIds: ids.length ? ids : undefined};
                      // });
                    }}
                  />
                </div>
              </>
            )}
            {type === '1' && (
              <>
                <div
                  style={{
                    width: '100%'
                  }}>
                  <label className={'form-label'}>{intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PERSON' })}</label>

                  <AsyncPaginate
                    loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
                    noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
                    debounceTimeout={500}
                    styles={selectStyle}
                    closeMenuOnSelect={true}
                    placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
                    loadOptions={getPeople}
                    loadOptionsOnMenuOpen
                    value={person}
                    onChange={(values: any) => {


                      setGroup({ label: '', value: 0 });
                      setPerson(values);
                      // const ids = values ? [...values.map(({value}: any) => value)] : [];

                      // setIdsVisitsActived(ids);
                      // setQueryParams((prev: any) => {
                      //   return {...prev, personsIds: ids.length ? ids : undefined};
                      // });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setIsModalSpaces(false);
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' })}
          </Button>

          <button
            className='btn btn-primary'
            disabled={isProgressRequest || isRead}
            onClick={async () => {
              handleSave();
            }}>
            {intl.formatMessage({ id: 'PERSON.NEWPERSON_SAVE_BUTTON' })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
