import React, { useEffect, useState } from 'react';
import { LicenseCard } from './components/LicenseCard';

import * as S from './styles';
import { useIntl } from 'react-intl';
import { LicenseModel, getLicense } from './useCases/GetLicense';
import { LicenseCardSkeleton } from './components/LicenseCard/skeleton';

export function LicensesTab() {
  const intl = useIntl();
  const [license, setLicense] = useState<LicenseModel | undefined>(undefined);
  const [status, setStatus] = useState<'loading' | 'fetching' | 'idle' | 'error'>('loading');

  useEffect(() => {
    const handleFetch = async () => {
      try {
        setStatus('loading');
        const data = await getLicense.exec();

        setLicense(data);
      } catch (e) {
        setStatus('error');
      }

      setStatus('idle');
    };

    handleFetch();
  }, []);

  return (
    <S.Wrapper className='tab-pane active'>
      {status === 'idle' && license !== undefined && (
        <>
          <LicenseCard
            type='user'
            title={intl.formatMessage({ id: 'USERS_LABEL' })}
            subTitle={intl.formatMessage({ id: 'PEOPLE_AND_VISITORS_LABEL' })}
            total={license.user.total}
            value={license.user.used}
            percentage={license?.user.percentage}
          />
          <LicenseCard
            type='device'
            title={intl.formatMessage({ id: 'DEVICES_LABEL' })}
            subTitle={intl.formatMessage({ id: 'ONLY_ACTIVES_LABEL' })}
            total={license.device.total}
            value={license.device.used}
            percentage={license.device.percentage}
          />
        </>
      )}
      {status === 'loading' && (
        <>
          <LicenseCardSkeleton />
          <LicenseCardSkeleton />
        </>
      )}
      {status === 'error' && <span>{intl.formatMessage({ id: 'WENT_WRONG' })}</span>}
    </S.Wrapper>
  );
}
