import { IntlShape } from 'react-intl';

const typeReportPDF = (props: {
  type: number,
  intl: IntlShape
}) => {
  switch (props.type) {
    case 41: {
      return props.intl.formatMessage({ id: 'REPORTS.AUDITLOG' })
    }

    case 40: {
      return props.intl.formatMessage({ id: 'LOGREGISTER.VEHICLE_LOCATION' })
    }

    case 39: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_PARKING_LENGTH_OF_STAY' })
    }

    case 38: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LENGTH_STAY_CURRENT_AREA' })
    }

    case 37: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_EXPIRED_VISITORS' })
    }

    case 36: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LOCATION_VISITORS' })
    }

    case 35: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_LOCATION_PEOPLE' })
    }

    case 34: {
      return props.intl.formatMessage({ id: 'REPORTS.CURRENT_STATUS_PEOPLE_VISITORS_PRESENT_COMPANY' })
    }

    case 33: {
      return props.intl.formatMessage({ id: 'REPORTS.BLACK_LIST' })
    }

    case 32: {
      return props.intl.formatMessage({ id: 'REPORTS.PHOTOS' })
    }

    case 31: {
      return props.intl.formatMessage({ id: 'BADLOGIN_TITLE' })
    }

    case 30: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.REGISTER_EXPIRED_DOCUMENTS' })
    }

    case 29: {
      return props.intl.formatMessage({ id: 'REGISTER_CREDIT_TO_USER_TITLE' })
    }

    case 28: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_COMPANIES' })
    }

    case 27: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS_BY_VISITORS_GROUP' })
    }

    case 26: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS_BY_COMPANIES' })
    }

    case 25: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS_BY_PEOPLE_GROUP' })
    }

    case 24: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.VISITORS_RECORDS' })
    }

    case 23: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_RECORDS' })
    }

    case 22: {
      return props.intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_GROUPAREA' })
    }

    case 21: {
      return props.intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_PEOPLEAREA' })
    }

    case 20: {
      return props.intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_GROUPPEOPLE' })
    }

    case 19: {
      return props.intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_COMPANIES' })
    }

    case 18: {
      return props.intl.formatMessage({ id: 'LOGSPERMISSIONS_VIEW_PEOPLE' })
    }

    case 17: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_AUTH' })
    }

    case 16: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_DEVICE' })
    }

    case 15: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_PARKING' })
    }

    case 14: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_VEHICLE' })
    }

    case 13: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_PEOPLE' })
    }

    case 12: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_GROUP' })
    }

    case 11: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.BY_DATE' })
    }

    case 10: {
      return props.intl.formatMessage({ id: 'ACCESS_VEHICLES_LOGS.GENERAL' })
    }

    case 9: {
      return props.intl.formatMessage({ id: 'ACCESS_LOGS.BLACK_LIST' })
    }

    case 8: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.AUTH_ACCESS_LOGS' })
    }

    case 7: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.DEVICES_ACCESS_LOGS' })
    }

    case 6: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.AREAS_ACCESS_LOGS' })
    }

    case 5: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS_GUEST_ACCESS' })
    }

    case 4: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.PEOPLE_ACCESS_LOGS' })
    }

    case 3: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS_BY_GROUP_AND_COMPANY' })
    }

    case 2: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.DATETIME_ACCESS_LOGS' })
    }


    case 1: {
      return props.intl.formatMessage({ id: 'ASIDE.MENU.ITEM.ACCESS_LOGS' })
    }
    default: {
      return ' '
    }
  }
}


const statusPDF = (status: number) => {
  switch (status) {
    case 3: {
      return 'Finalizado'
    }

    case 2: {
      return 'Processando'
    }

    default: {
      return ' - '
    }
  }
}

export { typeReportPDF, statusPDF }