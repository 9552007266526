import React from 'react'
import { notes } from './releases.json'
import { Container } from './styles'

export function List() {



  function versionFormated(version: string, hotfix: boolean) {
    const [day, month, year] = version.split('/')

    if (hotfix) {
      return `Hotfix ${year}.${month}.${day}`
    }

    return `Versão ${year}.${month}.${day}`
  }

  const notesFormatted = notes.map(({ version, hotfix, features, showDate }) => {
    return {
      ...notes,
      version,
      hotfix,
      features,
      showDate,
      versionBuild: versionFormated(version, hotfix)
    }
  }).reverse()




  return (
    <Container>
      {
        notesFormatted.map(note => {
          return (
            <article key={note.versionBuild}>
              <span style={{ opacity: note.showDate ? "1" : '0' }}>{note.version}</span>
              <div>
                <section>
                  <p><strong>{note.versionBuild}</strong></p>
                  <ul>
                    {
                      note.features.map(feature => {
                        return (
                          <li key={feature.title}>{feature.title}</li>
                        )
                      })
                    }
                  </ul>
                </section>
              </div>
            </article>
          )
        })
      }
    </Container>
  )
}