import cookie from 'js-cookie';
import { IntlShape } from 'react-intl';

interface IsetCustomLabel {
  inputName: string,
  value: string
}
export class CustomLabels {

  setCustomLabel({ inputName, value }: IsetCustomLabel) {

    if (value) {
      cookie.set(`idsegurecustom-${inputName}`, value)
    }
  }

  getCustomLabel({ defaultName, inputName, isTagNew = false, intl }: {
    inputName: string, defaultName: string, isTagNew?: boolean, intl: IntlShape
  }) {
    const name = cookie.get(`idsegurecustom-${inputName}`)

    if (!name || name === null) return defaultName

    if (isTagNew) {
      return `${intl.formatMessage({ id: 'NEW' })} ${name}`
    }

    return `${name}`
  }



}
