/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { indexedDB } from 'src/utils/DataBaseIndexed';
import { createVariables } from 'src/utils/window';
import { Routes } from '../app/Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { ALTER_LANGUAGUE_CONFIG } from './modules/Config/_redux/actions';
import { defaultDateTime } from 'src/utils/defaultDateTime';

interface AppProps {
  store: Store;
  persistor: Persistor;
  basename: string;
}

type Variables = {
  accessRule: {
    description: string;
    people: any[];
    visits: any[];
    types: any[];
    groups: any[];
    schedules: any[];
    where: any[];
    allWhere: any[];
  };
};

type ISizePerPageList = {
  text: string;
  value: number;
};

declare global {
  interface Window {
    variables: undefined | Variables;
    isAdmin: boolean;
    sizePerPageList: ISizePerPageList[];
  }
}

window.variables = undefined;
window.sizePerPageList = [
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];

export default function App({ store, persistor, basename }: AppProps) {
  useEffect(() => {
    createVariables();

    defaultDateTime.__init__config__app()

    // indexedDB.indexedStarted().then(async () => {
    //   const data: any = await indexedDB.loadConfig();


    //   if (data.length === 0) return;

    //   dispatch(ALTER_LANGUAGUE_CONFIG({language: data[0].language}));
    // });
  }, []);

  return (
    <>
      <div id="body-clone"></div>
      {/* /* Provide Redux store */}
      <div id="ALL_CONTENT">
        <Provider store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Routes />

                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </Provider>
      </div>
    </>

  );
}
