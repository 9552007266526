
import React, { useState } from 'react'
import { Card } from 'react-bootstrap';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from 'src/_metronic/_partials/controls';
import BootstrapTable, { SortOrder } from 'react-bootstrap-table-next';
import { NoRecordsFoundMessage, PleaseWaitMessage, toAbsoluteUrl } from 'src/_metronic/_helpers';

import { Checkbox } from '@material-ui/core';

import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { TableNoData } from 'src/_metronic/layout/components/TableNoData';
import { useParkingProvider } from '../Context/ParkingContext'

import { Header } from '../Components/Header';
import { Modals } from '../Components/Modals';
import { ActiveModal } from 'src/app/components/modals/Active';
import { BulkModal } from 'src/app/components/modals/Bulk';
import { useIntl } from 'react-intl';
import { bulkService } from 'src/services/bulk';

export function List() {
  const intl = useIntl();
  const [idsSelectedToRemoved, setIdsSelectedToRemoved] = useState<number[]>([])
  const [isModalRemoveAllItensSelectedOpen, setIsModalRemoveAllItensSelectedOpen] = useState(false)

  const [idsToReactived, setIdsToReactived] = useState<number[]>([])
  const [isModalReativePersons, setIsModalReativePersons] = useState(false)

  const { tableConfig, modals, fn } = useParkingProvider()
  const { columns, loadingDataToTable, optionPagination, data, alterProps } = tableConfig
  const { modalToggle, parkingSelectedToModal } = modals

  function RenderAction(item: any) {
    function checkedRow(id: number) {
      const idIsSelected = idsSelectedToRemoved.find(item => item === id)
      if (!idIsSelected) {
        setIdsSelectedToRemoved([...idsSelectedToRemoved, id])

      } else {
        const ids = idsSelectedToRemoved.filter(item => item !== id)
        setIdsSelectedToRemoved(ids)
      }


    }

    function isChecked(id: number) {
      return idsSelectedToRemoved.includes(id)

    }

    function isCheckedActived(id: number) {
      return idsToReactived.includes(id)
    }

    function checkedRowActived(id: number) {
      const idIsSelected = idsToReactived.find(item => item === id)
      if (!idIsSelected) {
        setIdsToReactived([...idsToReactived, id])

      } else {
        const ids = idsToReactived.filter(item => item !== id)
        setIdsToReactived(ids)
      }


    }

    if (item.isEnabled) {
      return (
        <>
          <Checkbox defaultChecked checked={isChecked(item.id)} onChange={() => {
            checkedRow(item.id)
          }} />
          <Link
            className='btn btn-icon btn-light btn-hover-primary btn-sm mx-3'
            to={`/parkingregister/manager`}
            onClick={() => {
              sessionStorage.setItem('@CONTROLID@/parking/edit', item.id);
            }}>
            <span className='svg-icon svg-icon-md svg-icon-primary'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')} />
            </span>
          </Link>

          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              // handleDelete(item.id, item.personName);

              parkingSelectedToModal(item.id, item["0"])
              modalToggle('delete')
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      );
    }

    return (
      <>
        <Checkbox defaultChecked checked={isCheckedActived(item.id)} onChange={() => {
          checkedRowActived(item.id)
        }} />
        <a
          className='btn btn-icon btn-icon btn-light btn-hover-success btn-sm mx-3'
          onClick={() => {
            parkingSelectedToModal(item.id, item.name)
            modalToggle('reactive')
          }}>
          <span className='svg-icon svg-icon-md svg-icon-success'>
            <SVG src={toAbsoluteUrl('/media/svg/icons/Navigation/Check.svg')} />
          </span>
        </a>
      </>
    );
  }

  const dataToRender = data.map(item => {


    return {
      ...item,
      '1': item['1'] === 0 ? '-' : item['1'],
      '2': RenderAction(item)
    }
  })

  const columnsToRender = columns
    // .filter((_, index) => columnIndexActivedVisitorExpired.includes(index + 1))
    .map((item: any, index) => {
      if (index === 0) {
        delete item.align;
        delete item.headerAlign;

        item.style = {
          minWidth: '170px'
        };
        return item;
      }



      return item;
    });



  return (
    <>

      <Card style={{ marginTop: 80 }} className={'card card-custom gutter-b mt-md-0'}>
        <Header
          idsSelectedToRemoved={idsSelectedToRemoved}
          idsToReactived={idsToReactived}
          setIsModalReativePersons={(value: boolean) => setIsModalReativePersons(value)}
          setIsModalRemoveAllItensSelectedOpen={(value: boolean) => setIsModalRemoveAllItensSelectedOpen(value)}
        />
        <Card.Body>
          <PaginationProvider
            pagination={paginationFactory(
              optionPagination
            )}>
            {({ paginationProps, paginationTableProps }: any) => {

              return (
                <Pagination isLoading={false} paginationProps={paginationProps}>

                  {loadingDataToTable ? (
                    <TableNoData columnsToTable={columns} paginationTableProps={paginationTableProps} />
                  ) : (
                    <BootstrapTable
                      onTableChange={(type, props) => {

                        alterProps(type, props)

                        // if(type === 'pagination'){
                        //   handlerAlterLimitItens(props.sizePerPage)

                        //   if (props.page !== 0) {
                        //      handlerAlterPage(props.page);
                        //   }
                        // }

                        // setSizePerPage(props.sizePerPage);
                      }}
                      // defaultSorted={[defaultSortedColumn]}
                      wrapperClasses='table-responsive'
                      bordered={false}
                      classes='table table-head-custom table-vertical-center overflow-hidden'
                      remote
                      bootstrap4
                      keyField='id'
                      data={dataToRender}
                      columns={columnsToRender}
                      {...paginationTableProps}>

                      <PleaseWaitMessage entities={dataToRender} />
                      <NoRecordsFoundMessage entities={[dataToRender]} />

                    </BootstrapTable>
                  )}
                </Pagination>
              );
            }}
          </PaginationProvider>
        </Card.Body>

      </Card>
      <Modals />

      <BulkModal
        titles={
          {
            toggle: intl.formatMessage({ id: 'TOGGLE.MSG.DELETE.BULK.PARKING' }),
            headerInative: intl.formatMessage({ id: 'INACTIVATE.HEADER.DELETE.BULK.PARKING' }),
            headerDelete: intl.formatMessage({ id: 'MODAL.MSG.DELETE.BULK.PARKING' }),
            bodyInative: intl.formatMessage({ id: 'MODAL.MSG.INACTIVATE.BULK' }),
            bodyDelete: intl.formatMessage({ id: 'CONFIRM.MSG.DELETE.BULK' })
          }
        }

        toastMessage={
          {
            error: intl.formatMessage({ id: 'MODAL.DELETE.TOAST.ERROR' }),
            success: intl.formatMessage({ id: 'MODAL.DELETE.TOAST.SUCCESS' })
          }
        }

        buttons={{
          cancel: intl.formatMessage({ id: 'PERSON.NEWPERSON_CANCEL_BUTTON' }),
          delete: intl.formatMessage({ id: 'TOAST.DELETE' }),
          inative: intl.formatMessage({ id: 'TOAST.DELETE_BUTTON' })
        }}
        data={{
          idsToTemoved: idsSelectedToRemoved,
          resetIds: () => setIdsSelectedToRemoved([]),
          save: async (ids: number[], removed: boolean) => {

            await bulkService.removeParking(ids, removed)
          },
          reload: () => {
            // clearFilter()
            fn.load('')
          }
        }}
        isVisibleModal={isModalRemoveAllItensSelectedOpen}
        closeModal={() => setIsModalRemoveAllItensSelectedOpen(false)}
      />

      <ActiveModal
        titles={
          {
            headerInative: intl.formatMessage({ id: 'MODAL.ACTIVED.HEADER.PARKING' }),
            bodyInative: intl.formatMessage({ id: 'MODAL.ACTIVED.MSG' }),
          }
        }

        toastMessage={
          {
            error: intl.formatMessage({ id: 'MODAL.TOAST.ERROR' }),
            success: intl.formatMessage({ id: 'MODAL.TOAST.SUCCESS' })
          }
        }

        buttons={{
          cancel: intl.formatMessage({ id: 'PAGE.BLUEPRINT.BTN.Cancel' }),
          inative: intl.formatMessage({ id: 'TOAST.ENABLE' })
        }}
        data={{
          idsToTemoved: idsToReactived,
          resetIds: () => setIdsToReactived([]),
          save: async (ids: number[]) => {
            await bulkService.activedParking(ids)
          },
          reload: () => {
            fn.load('')
          }
        }}
        isVisibleModal={isModalReativePersons}
        closeModal={() => setIsModalReativePersons(false)}
      />
    </>
  )
}