import { useSelector } from 'react-redux';
import { USER_PERMISSIONS_PROPS } from './types';
import { useHistory } from 'react-router';

export type viewMenuProps = { idModule: number; idFunctionality: number };

export function usePermisssion() {
  const history = useHistory();
  const permissions = useSelector<any, USER_PERMISSIONS_PROPS>((stage) => stage.userPermissions);

  function viewIsVisible(id: number) {
    const permission = permissions.modules.find((permission) => permission.id === id);

    if (!permission) {
      history.push('/dashboard');
      return;
    }

    if (permission.permission === 1) {
      history.push('/dashboard');
      return;
    }
  }

  function viewMenu({ idModule, idFunctionality }: viewMenuProps) {
    const itensMenuActivity: number[] = [];
    const functionsModule = permissions.functionalities.filter((permission) => permission.id_module === idModule);

    const functionsIsAccess = functionsModule.filter((permission) => {
      if (permission.permission !== 1) {
        itensMenuActivity.push(permission.id);
        return permission;
      }
    });

    return {
      menuFatherIsVisible: functionsIsAccess.length > 0,
      menuItemIsVisible: itensMenuActivity.includes(idFunctionality)
    };
  }

  function redirectView(ifFunctionality: number) {
    const permissionView = permissions.functionalities.find((item) => item.id === ifFunctionality);
    // alert(ifFunctionality);

    if (!permissionView) return;


    if (permissionView.permission != 1) {
      return;
    }

    if (permissionView.permission == 1) {
      history.push('/dashboard');
    }
  }

  function actionsIsDesatived(ifFunctionality: number) {
    const permissionView = permissions.functionalities.find((item) => item.id === ifFunctionality);

    switch (permissionView?.permission) {
      case 3:
        return false;
      default:
        return true;
    }
  }

  function actionsIsDesativedToModule(idModule: number) {
    const permissionView = permissions.modules.find((item) => item.id === idModule);

    if (!permissionView) {
      return false;
    }

    switch (permissionView?.permission) {
      case 3:
        return false;

      case 2:
        return false;

      case 4:
        return false;
      default:
        return true;
    }
  }

  function moduleOnly(id: number) {
    const moduleData = permissions.modules.find((item) => item.id === id);

    if (moduleData) {
      return {
        isRedirect: moduleData.permission == 1,
        isLevel: moduleData.permission === 2
      };
    }

    return {
      isRedirect: true,
      isLevel: true
    };
  }

  return {
    viewIsVisible,
    viewMenu,
    redirectView,
    actionsIsDesatived,
    moduleOnly,
    actionsIsDesativedToModule,
    permissions
  };
}
