import React from 'react';
import {useLang} from './Metronici18n';
import {IntlProvider} from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/pt';

import enMessages from './messages/en';
import esMessages from './messages/es';
import ptMessages from './messages/pt';

export const allMessagesList = {
  pt: ptMessages,
  en: enMessages,
  es: esMessages
};

export function I18nProvider({children}) {
  const locale = useLang();
  const messages = allMessagesList[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
