import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, CardHeader, LoadingDialog } from '../../../_metronic/_partials/controls';
import { ArticleContainer, FooterContainer, NavContainer, RowSelect } from './styles/create';
import { toAbsoluteUrl } from 'src/_metronic/_helpers';
import BootstrapTable from 'react-bootstrap-table-next';
import { ModalSelectUsers } from './modalSelectedUser';
import SVG from 'react-inlinesvg';
import {
  activeOperation,
  editOperation,
  getAllFunctions,
  getAllModules,
  getOperation,
  saveOperation
} from 'src/services/permissions';
import { useHistory, useParams } from 'react-router-dom';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { useTableHooks } from './hooks/table';
import { Button, Modal } from 'react-bootstrap';
import { usePermisssion } from 'src/redux/userPermissions/usePermission';

import { FaCogs } from 'react-icons/fa';
import { ModalFunctions } from './modalFunctions';
import { ToggleSwitch } from 'src/_metronic/_partials/controls/forms/ToggleSwitch';
import { AsyncPaginate } from 'react-select-async-paginate';
import { standardTranslation } from 'src/utils/standardTranslation';
import { findGroups } from 'src/services/personsCrud';
import { useIntl } from 'react-intl';
import { Input, RadioInput } from '../vehicles/pages/CreateVehiclePage/components/Input';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import { ModelSegregationForm } from './components/model-segregation-form';
import { Show } from 'src/app/components/Show';
import { Loading } from 'src/_metronic/layout/components/Loading';
import { LoadingScreen } from '../Visitors/pages/remote-registration/components/form/styles';

const selectStyle = {
  control: (styles: any) => ({
    ...styles,
    border: '1px solid #E4E6EF',
    '&:hover': {
      cursor: 'pointer'
    }
  })
};

export function CreatePermission({ location }: any) {
  useEffect(() => {
    return () => {
      localStorage.removeItem('@CONTROLID@_OPERATION_RULE_EDIT')
    }
  }, [])

  const [requestsCompleted, setRequestsCompleted] = useState<boolean[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const intl = useIntl();
  const { actionsIsDesatived } = usePermisssion();
  const { id }: any = useParams();
  const { INTL } = useTableHooks();
  const history = useHistory();
  const formRef = useRef<HTMLButtonElement>(null);

  const [enableSegregationData, setEnableSegregationData] = useState(false);
  const columns = [
    {
      dataField: 'module',
      text: INTL('OPERATOR_ROLE_TAB_TREE_TABLE_COLLUm_MODULE'),
      style: {
        minWidth: '30vw'
      }
    },

    {
      dataField: 'access',
      text: INTL('OPERATOR_ROLE_TAB_TREE_TABLE_COLLUm_ACCESS')
    }
  ];

  const [isModalFunctionsActived, setIsModalFunctionsActived] = useState(false);
  const [moduleActived, setModuleActived] = useState(0);

  const columnsUserActived = [
    {
      dataField: 'name',
      text: INTL('OPERATOR_ROLE_TAB_TWO_TABLE_COLLUm_NAME'),
      style: {
        minWidth: '20vw'
      }
    },

    {
      dataField: 'email',
      text: INTL('OPERATOR_ROLE_TAB_TWO_TABLE_COLLUm_EMAIL'),
      style: {
        minWidth: '20vw'
      }
    },
    {
      dataField: 'remove',
      text: INTL('OPERATOR_ROLE_TAB_TWO_TABLE_COLLUm_ACTIONS')
    }
  ];

  const columnsUserALLActived = [
    {
      dataField: 'name',
      text: INTL('OPERATOR_ROLE_TAB_TWO_TABLE_COLLUm_NAME'),
      style: {
        minWidth: '40vw'
      }
    },

    {
      dataField: 'remove',
      text: INTL('OPERATOR_ROLE_TAB_TWO_TABLE_COLLUm_ACTIONS')
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [taShow, setTabShow] = useState(1);
  const [isModalPersonsActived, setIsModalPersonsActived] = useState(false);

  const [usersSelecteds, setUserSelecteds] = useState<any[]>([]);
  const [usersPreSelecteds, setUserPreSelecteds] = useState<any[]>([]);

  const [systemMOdules, setSystemModules] = useState<any>([]);
  const [systemMOdulesIds, setSystemMOdulesIds] = useState<any>([]);

  const [systemMOdulesFunctions, setSystemMOdulesFunctions] = useState<any>([]);
  const [systemMOdulesFunctionsIds, setSystemMOdulesFunctionsIds] = useState<any>([]);

  const [allUsersModal, setAllUsersModal] = useState(false);
  const [allUsersModalpre, setAllUsersModalPre] = useState(false);

  const [modalRemoveUser, setModalRemoveUser] = useState({
    enable: false,
    name: '',
    id: 0
  });

  const [isRead, setIsRead] = useState(false);

  const [TitleModal, setTitleModal] = useState('');

  const [limitGroups, setLimitGroups] = useState(false);
  const [limitCompanies, setLimitCompanies] = useState(true);
  const [limitGroupsAndCompaniesIsSelected, setLimitGroupsAndCompaniesIsSelected] = useState(false);

  const [groupsAnCompaniesSelecteds, setGroupsAnCompaniesSelecteds] = useState<any[]>([]);

  const [modelSegregation, setModelSegregation] = useState(1);

  function toggleModal(value: boolean) {
    setIsModalPersonsActived(value);
  }

  function handleSelectedUser(user: any) {


    if (Array.isArray(user)) {
      setAllUsersModalPre(true);
      setUserPreSelecteds([]);
      // setUserPreSelecteds([...user]);
      return;
    }

    const userisExists = usersPreSelecteds.find((item: any) => item.id === user.id);

    if (userisExists) {
      setUserPreSelecteds(usersPreSelecteds.filter((item: any) => item.id !== user.id));
      return;
    }

    setUserPreSelecteds([...usersPreSelecteds, user]);
  }

  function hanldeAddListUsers() {
    setUserSelecteds([...usersSelecteds, ...usersPreSelecteds]);
    setUserPreSelecteds([]);

    if (allUsersModalpre) {
      setAllUsersModal(true);
      setAllUsersModalPre(false);
    }
    setIsModalPersonsActived(false);
  }

  function handleRemoveUser(id: number) {
    if (id === -10) {
      setAllUsersModal(false);
      return;
    }

    setUserSelecteds((oldStage) => {
      return oldStage.filter((item) => item.id !== id);
    });
  }

  const getModulePermissionOptions = (moduleId: number, showFunctions = true) => {
    switch (moduleId) {
      case 1:
        return (
          <>
            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_READ')}</option>
          </>
        );

      case 2:
        return (
          <>
            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
            <option value='3'>{INTL('OPERATOR_ROLE_OPTION_READANDWRITE')}</option>
          </>
        );

      case 3:
        return (
          <>
            {showFunctions && <option value='0'>{INTL('OPERATOR_ROLE_OPTION_BY_FUNC')}</option>}
            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
            <option value='3'>{INTL('OPERATOR_ROLE_OPTION_READANDWRITE')}</option>
          </>
        );
      case 4:
        return (
          <>
            {showFunctions && <option value='0'>{INTL('OPERATOR_ROLE_OPTION_BY_FUNC')}</option>}

            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
            <option value='3'>{INTL('OPERATOR_ROLE_OPTION_READANDWRITE')}</option>
          </>
        );

      case 5:
        return (
          <>
            {showFunctions && <option value='0'>{INTL('OPERATOR_ROLE_OPTION_BY_FUNC')}</option>}

            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_READ')}</option>
          </>
        );
      case 6:
        return (
          <>
            {showFunctions && <option value='0'>{INTL('OPERATOR_ROLE_OPTION_BY_FUNC')}</option>}

            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
            <option value='3'>{INTL('OPERATOR_ROLE_OPTION_READANDWRITE')}</option>
          </>
        );
      case 7:
        return (
          <>
            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
          </>
        );
      case 8:
        return (
          <>
            <option value='1'>{INTL('OPERATOR_ROLE_OPTION_NO')}</option>
            <option value='2'>{INTL('OPERATOR_ROLE_OPTION_JUST_READ')}</option>
          </>
        );
    }
  };

  function renderSelected(id = 0, title = '') {
    return (
      <RowSelect>
        <select
          defaultValue='1'
          name={`s${id}`}
          className='custom-select'
          onChange={(e) => {
            if (e.target.value === '0' && id != 1 && id != 2) {
              setIsModalFunctionsActived(true);
              setModuleActived(id);

              if (e.target.value === '0') {
                document.querySelector(`#complements${id}`)?.classList.add('btn-primary');
                setTitleModal(title);
              }

              return;
            }

            if (e.target.value !== '0') {
              document.querySelector(`#complements${id}`)?.classList.remove('btn-primary');
            }

            const allSelectsModules = document.querySelectorAll(`#FORM [data-module="${id}"]`);

            allSelectsModules.forEach((element) => {
              //@ts-ignore
              element.value = e.target.value;
            });
          }}>
          {getModulePermissionOptions(id)}
        </select>

        <Show show={id > 2}>
          <button
            className='btn btn-default'
            type='button'
            id={`complements${id}`}
            onClick={() => {
              const element = document.querySelector(`[name="s${id}"]`);

              //@ts-ignore
              if (element.value === '0') {
                setIsModalFunctionsActived(true);
                setModuleActived(id);
              }
            }}>
            <FaCogs />
          </button>
        </Show>
      </RowSelect>
    );
  }

  function renderSelectedFunction(name: string, module: number, idFunction: number) {
    return (
      <select
        defaultValue='1'
        data-function={idFunction}
        data-module={module}
        name={`F${name}`}
        className='custom-select'>
        {getModulePermissionOptions(module, false)}
      </select>
    );
  }

  function ENUM() {
    if (limitGroupsAndCompaniesIsSelected) {
      return 3;
    }

    if (limitGroups) {
      return 2;
    }

    if (limitCompanies) {
      return 1;
    }

    return 1;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();

    setIsSaving(true)

    // if (isRead) {
    //   return;
    // }

    try {
      const modulesPermissionsData: any[] = [];

      systemMOdulesIds.forEach((module: any) => {
        if (e.target.elements[`s${module}`]) {
          modulesPermissionsData.push({
            moduleId: module,
            permission: e.target.elements[`s${module}`].value
          });
        }
      });

      const functionsPermissionsData: any[] = [];
      systemMOdulesFunctionsIds.forEach((module: any) => {
        const element = e.target.elements[`F${module}`];

        if (element) {
          functionsPermissionsData.push({
            functionId: element.getAttribute('data-function'),
            permission: element.value
          });
        }
      });

      const description = e.target.elements.name.value.trim();

      const dataToApi = {
        'description': description,
        enableSegregationData,
        'personOperatorRole': usersSelecteds.map((person: any) => ({ personId: person.id })),
        'operatorRoleModule': modulesPermissionsData,
        'operatorRoleFunction': functionsPermissionsData,
        segregationDataType: modelSegregation,
        segregationDataGroups: groupsAnCompaniesSelecteds.map((item) => item.value).toString()
      };



      if (!description) {
        setTabShow(1);
        execToast('error', INTL('OPEPRATOR_ROLE_DESCRIPION.EMPYT'), 3000);

        return;
      }

      if (usersSelecteds.length === 0) {
        setTabShow(2);
        execToast('error', INTL('OPERATOR_ROLE_TOAST_ERROR_SAVE_NO_PERSONS'), 3000);

        return;
      }

      if (id) {

        await editOperation({ id, ...dataToApi });

        execToast('success', INTL('OPERATOR_ROLE_TOAST_EDIT'), 3000);
        history.push('/access');
        setIsSaving(false)
      } else {
        await saveOperation(dataToApi);
        execToast('success', INTL('OPERATOR_ROLE_TOAST_SAVE'), 3000);
        setIsSaving(false)
        history.push('/access');
      }

    } catch {
      if (id) {
        execToast('error', INTL('OPERATOR_ROLE_TOAST_EDIT_ERROR'), 3000);
      } else {
        execToast('error', INTL('OPERATOR_ROLE_TOAST_save_ERROR'), 3000);
      }

      setIsSaving(false)
    }
  }

  function allModulesAndFunctions() {
    setIsLoading(true);
    getAllModules()
      .then((reponse) => {

        setSystemModules(() => {
          return reponse.data.data.value.map((item: any) => {
            return {
              module: INTL(item.description),
              access: renderSelected(item.id, INTL(item.description))
            };
          });
        });

        setSystemMOdulesIds(() => {
          return reponse.data.data.value.map((item: any) => item.id);
        });

        setRequestsCompleted((stage) => {
          return [...stage, true]
        })
        return reponse.data.data.value;
      })
      .then(async (modules) => {
        const all_model: any = [];
        const ALL_IDS: any[] = [];

        const { data } = await getAllFunctions();


        data.data.modules.forEach((item: any) => {
          const model = {
            idModule: item.moduleId,
            name: item.description,
            data: item.funcionalitys.map((item2: any) => {
              ALL_IDS.push(`${item.moduleId}${item2.id}`);
              return {
                module: INTL(item2.description),
                access: renderSelectedFunction(`${item.moduleId}${item2.id}`, item.moduleId, item2.id)
              };
            })
          };
          all_model.push(model);
        });
        // for (let module of modules) {
        //   const {data} = await getAllFunctions(module.id);

        //   const model = {
        //     idModule: module.id,
        //     name: module.description,
        //     data: data.data.map((item: any) => {
        //       ALL_IDS.push(`${module.id}${item.id}`);
        //       return {
        //         module: item.description,
        //         access: renderSelectedFunction(`${module.id}${item.id}`, module.id, item.id)
        //       };
        //     })
        //   };

        //all_model.push(model);
        //}
        // const response = await getAllFunctions();



        setSystemMOdulesFunctions(all_model);
        setSystemMOdulesFunctionsIds(ALL_IDS);
        setRequestsCompleted((stage) => {
          return [...stage, true]
        })
        return;
      })
      .then(() => {
        if (id) {
          const valuesString = localStorage.getItem('@CONTROLID@_OPERATION_RULE_EDIT');
          if (!valuesString) return;
          const values = JSON.parse(valuesString);

          //@ts-ignore
          document.getElementById('name').value = INTL(values.description);

          setEnableSegregationData(!!values.enableSegregationData);

          values.operatorRoleModule.forEach((item: any) => {
            const isReports = item.moduleId === 5;
            const shouldFallbackToDefault = isReports && item.permission === 3;
            if (shouldFallbackToDefault) {
              //@ts-ignore
              return (document.querySelector(`[name="s${item.moduleId}"]`).value = 1);
            }

            //@ts-ignore
            document.querySelector(`[name="s${item.moduleId}"]`).value = item.permission;

            if (item.permission == 0) {
              document.querySelector(`#complements${item.moduleId}`)?.classList.add('btn-primary');
            }
          });

          values.operatorRoleFunction.forEach((item: any) => {
            //@ts-ignore
            document.querySelector(`[data-function="${item.functionId}"]`).value = item.permission;
          });

          if (values.segregationDataType === 3) {
            setLimitGroups(false);
            setLimitCompanies(false);
            setLimitGroupsAndCompaniesIsSelected(true);
          }
          if (values.segregationDataType === 2) {
            setLimitCompanies(false);
            setLimitGroupsAndCompaniesIsSelected(false);
            setLimitGroups(true);
          }

          if (values.segregationDataType === 1) {
            setLimitGroups(false);
            setLimitGroupsAndCompaniesIsSelected(false);

            setLimitCompanies(true);
          }

          setModelSegregation(values.segregationDataType);

          setUserSelecteds(
            values.personOperatorRole.map((item: any) => {
              return {
                id: item.personId,
                name: item.name,
                email: item.email
              };
            })
          );

          getOperation(id).then((resp) => {
            const groups = resp.data.data.groups?.map((g: any) => ({ value: g.id, label: g.description })) || [];

            setGroupsAnCompaniesSelecteds(groups);
          });

          setTimeout(() => {
            setRequestsCompleted((stage) => {
              return [...stage, true]
            })
          }, 700)
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function hiddenModal() {
    setIsModalFunctionsActived(false);
  }

  useEffect(() => {
    allModulesAndFunctions();
    setIsRead(actionsIsDesatived(12));
  }, []);

  const qwerStorage: any = localStorage.getItem('QWER');
  const hasLocalStorage = qwerStorage ? JSON.parse(qwerStorage) : null;
  const isAdmin = hasLocalStorage?.qwer;

  const rowValueStorage = localStorage.getItem('@CONTROLID@_OPERATION_RULE_EDIT');
  const hasRowValue = rowValueStorage ? JSON.parse(rowValueStorage) : null;
  const isAdminOperatorRole = hasRowValue?.isAdminOperatorRole;

  const allUsers = usersSelecteds.map((item: any) => ({
    name: item.name,
    email: item.email,
    remove: (
      <>
        {isAdminOperatorRole && isAdmin == false ? null : (
          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              // handleDelete(item.id, item.description);
              // handleRemoveUser(item.id);
              setModalRemoveUser({
                enable: true,
                name: item.name,
                id: item.id
              });
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        )}
      </>
    )
  }));

  const allUsersMODAL = [
    {
      name: INTL('OPEPRATOR_ROLE_ALL_USERS'),
      remove: (
        <>
          <a
            className='btn btn-icon btn-light btn-hover-danger btn-sm mx-3'
            onClick={() => {
              // handleDelete(item.id, item.description);

              setModalRemoveUser({
                enable: true,
                name: INTL('OPEPRATOR_ROLE_ALL_USERS'),
                id: -10
              });
            }}>
            <span className='svg-icon svg-icon-md svg-icon-danger'>
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Trash.svg')} />
            </span>
          </a>
        </>
      )
    }
  ];

  const getGroupsAndCompanies = async (...args: any[]) => {
    const [value, prevOptions, params] = args;
    const {
      data: {
        data: { data: people, page, pages }
      }
    } = await findGroups({
      status: 1,
      sortOrder: 'asc',
      sortField: 'Description',
      pageNumber: params?.page || 1,
      pageSize: 100,
      value: !!value ? value : undefined
    });

    return {
      options: people.map((el: any) => ({ value: el.id, label: standardTranslation(el.description, intl) })),
      hasMore: page < pages,
      additional: {
        page: (params?.page || 1) + 1
      }
    };
  };

  function renderColumnsName(name: string) {
    return columns.map((value, i) => {
      const up: any = { ...value };
      if (!i) {
        up.text = name;
      }
      return up;
    });
  }

  return (
    <Card fluidHeight={null} className={null}>
      <CardHeader
        icon={null}
        toolbar={null}
        className={null}
        labelRef={null}
        sticky={false}
        linkAddTitle={undefined}
        title={id ? INTL('OPERATOR_ROLE_TITLE_EDIT') : INTL('OPERATOR_ROLE_TITLE_CREATE')}
      />
      <CardBody fit={null} fluid={null} className={null}>
        <Show show={isLoading}>
          <LoadingScreen />
        </Show>
        <main style={{ padding: '0px 10px' }}>
          <NavContainer>
            <ul className='nav nav-dark nav-bold nav-tabs nav-tabs-line' data-remember-tab='tab_id' role='tablist'>
              <li className='nav-item'>
                <a
                  className={`nav-link ${taShow === 1 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    setTabShow(1);
                  }}>
                  {INTL('OPERATOR_ROLE_TAB_ONE')}
                </a>
              </li>

              <li className='nav-item'>
                <a
                  className={`nav-link ${taShow === 2 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    setTabShow(2);
                  }}>
                  {INTL('OPERATOR_ROLE_TAB_TWO')}
                </a>
              </li>
              {isAdminOperatorRole ? null : (
                <li className='nav-item'>
                  <a
                    className={`nav-link ${taShow === 3 ? 'active' : ''}`}
                    data-toggle='tab'
                    onClick={() => {
                      setTabShow(3);
                    }}>
                    {INTL('OPERATOR_ROLE_TAB_TREE')}
                  </a>
                </li>
              )}

              <li className='nav-item' style={{ display: 'none' }}>
                <a
                  className={`nav-link ${taShow === 4 ? 'active' : ''}`}
                  data-toggle='tab'
                  onClick={() => {
                    setTabShow(4);
                  }}>
                  {INTL('OPERATOR_ROLE_TAB_FOUR')}
                </a>
              </li>
            </ul>
          </NavContainer>

          <ArticleContainer onSubmit={handleSubmit}>
            <div className='tab-content pt-3'>
              <div
                className={`tab-pane`}
                style={{
                  display: taShow === 1 ? 'flex' : 'none',
                  width: '100%',
                  maxWidth: 600,
                  gap: '10px',
                  flexDirection: 'column'
                }}>
                <Input
                  label={INTL('OPERATOR_ROLE_INPUT_DESC')}
                  autoComplete='none'
                  autoFocus
                  disabled={id === 1}
                  placeholder={INTL('ACCESS_PROFILE.ENTER_A_NAME')}
                  id='name'
                />
                <ModelSegregationForm modelSegregation={modelSegregation} onChange={setModelSegregation} />

                {modelSegregation === 3 && (
                  <div style={{ flex: '1' }}>
                    <label htmlFor='name'> Empresa e grupos</label>

                    <AsyncPaginate
                      loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
                      noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
                      debounceTimeout={500}
                      styles={selectStyle}
                      closeMenuOnSelect={false}
                      placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
                      loadOptions={getGroupsAndCompanies}
                      loadOptionsOnMenuOpen
                      isMulti
                      value={groupsAnCompaniesSelecteds}
                      onChange={setGroupsAnCompaniesSelecteds}
                    />
                  </div>
                )}
              </div>

              <div className={`tab-pane ${taShow === 2 ? 'active' : ''}`}>
                <article>
                  <header className='tab_header'>
                    <button
                      disabled={allUsersModal || isRead}
                      className='btn btn-primary'
                      type='button'
                      onClick={() => toggleModal(true)}>
                      {INTL('OPERATOR_ROLE_TAB_TWO_BUTTON')}
                    </button>
                  </header>

                  <div>
                    {allUsersModal ? (
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-head-custom table-vertical-center overflow-hidden'
                        remote
                        bootstrap4
                        keyField='id'
                        data={allUsersMODAL}
                        columns={columnsUserALLActived}
                      />
                    ) : allUsers.length > 0 ? (
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-head-custom table-vertical-center overflow-hidden'
                        remote
                        bootstrap4
                        keyField='id'
                        data={allUsers}
                        columns={columnsUserActived}
                      />
                    ) : (
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-head-custom table-vertical-center overflow-hidden'
                        remote
                        bootstrap4
                        keyField='id'
                        data={[
                          {
                            name: INTL('ACCESS_PROFILE.NO_USER_SELECTED'),
                            email: '',
                            remove: ''
                          }
                        ]}
                        columns={columnsUserActived}
                      />
                    )}
                  </div>
                </article>
              </div>

              <div className={`tab-pane ${taShow === 3 ? 'active' : ''}`}>
                <BootstrapTable
                  wrapperClasses='table-responsive'
                  bordered={false}
                  classes='table table-head-custom table-vertical-center overflow-hidden'
                  remote
                  bootstrap4
                  keyField='id'
                  data={systemMOdules}
                  columns={columns}
                />
              </div>

              <div id='FORM' className={`tab-pane ${taShow === 4 ? 'active' : ''}`}>
                {systemMOdulesFunctions.map((m: any) => {
                  return (
                    <div className='function-panel' key={m.idModule} style={m.data.length > 0 ? {} : { display: 'none' }}>
                      <BootstrapTable
                        wrapperClasses='table-responsive'
                        bordered={false}
                        classes='table table-head-custom table-vertical-center overflow-hidden'
                        remote
                        bootstrap4
                        keyField='id'
                        data={m.data}
                        columns={renderColumnsName(INTL(m.name))}
                      />
                    </div>
                  );
                })}

                {/* <div className='function-panel'>
                  <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={functionsSystem.panelSinopt.values} columns={functionsSystem.panelSinopt.title} />
                </div>

                <div className='function-panel'>
                  <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={functionsSystem.registers.values} columns={functionsSystem.registers.title} />
                </div>

                <div className='function-panel'>
                  <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={functionsSystem.access.values} columns={functionsSystem.access.title} />
                </div>

                <div className='function-panel'>
                  <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={functionsSystem.reports.values} columns={functionsSystem.reports.title} />
                </div>

                <div className='function-panel'>
                  <BootstrapTable wrapperClasses='table-responsive' bordered={false} classes='table table-head-custom table-vertical-center overflow-hidden' remote bootstrap4 keyField='id' data={functionsSystem.configs.values} columns={functionsSystem.configs.title} />
                </div> */}
              </div>
            </div>

            <button ref={formRef} style={{ display: 'none' }} type='submit'>
              hidden
            </button>
          </ArticleContainer>
        </main>

        <FooterContainer>
          <button
            className='btn btn-danger'
            onClick={() => {
              history.push('/access');
            }}>
            {INTL('PAGE.BLUEPRINT.BTN.Cancel')}
          </button>

          {
            id ?

              isSaving ?
                (


                  <button
                    disabled={true}
                    className='btn btn-primary'
                  >
                    {
                      INTL('BTN.SAVING.TEXT')
                    }


                  </button>
                )
                :
                (
                  <button
                    disabled={isRead || requestsCompleted.filter(v => v === true).length < 3}
                    className='btn btn-primary'
                    onClick={() => {
                      formRef.current?.click();
                    }}>

                    {
                      requestsCompleted.filter(v => v === true).length < 3 ? INTL('PERSON.PERSONS_TABLE_LOADING') : INTL('PAGE.BLUEPRINT.BTN.SAVE')
                    }


                  </button>
                ) : (
                <button
                  disabled={isRead}
                  className='btn btn-primary'
                  onClick={() => {
                    formRef.current?.click();
                  }}>
                  {INTL('PAGE.BLUEPRINT.BTN.SAVE')}
                </button>
              )
          }

        </FooterContainer>

        <ModalSelectUsers
          isVisible={isModalPersonsActived}
          close={toggleModal}
          users={usersSelecteds}
          fnUserSeelected={handleSelectedUser}
          fnSave={hanldeAddListUsers}
        />

        <Modal show={modalRemoveUser.enable} onHide={() => setModalRemoveUser({ enable: false, id: 0, name: '' })}>
          <Modal.Header closeButton>
            <Modal.Title>{INTL('OPERATOR_ROLE_REMOVE_PERSON_TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {INTL('OPERATOR_ROLE_REMOVE_PERSON_QUESTION')} {modalRemoveUser.name} ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                setModalRemoveUser({ enable: false, id: 0, name: '' });
              }}>
              {INTL('OEPRATOR_ROLE_CONFIRM_REMOVE_USER_NO')}
            </Button>
            <Button
              variant='primary'
              onClick={async () => {
                handleRemoveUser(modalRemoveUser.id);
                setModalRemoveUser({ enable: false, id: 0, name: '' });
              }}>
              {INTL('OEPRATOR_ROLE_CONFIRM_REMOVE_USER')}
            </Button>
          </Modal.Footer>
        </Modal>

        <ModalFunctions
          title={TitleModal}
          hiddenModal={hiddenModal}
          data={systemMOdulesFunctions}
          isVisible={isModalFunctionsActived}
          moduleId={moduleActived}
        />
      </CardBody>
    </Card>
  );
}
