import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { AsyncPaginate } from 'react-select-async-paginate';
import { MdFilterAlt } from 'react-icons/md';

import { CardMonitor } from '../../../_metronic/layout/components/CardMonitor';

import { MonitorFilter, MonitoringContainer, MonitorResume } from './styles';
import { format } from 'date-fns';
import { Table } from './components/Table';
// @ts-ignore
import { CSSObjectWithLabel } from 'react-select';
import { getAllAreas } from 'src/services/areaCrud';
import {
  loadConfig,
  loadConfigDevices,
  loadConfigTypes,
  saveConfig,
  saveConfigDevices,
  saveConfigTypes
} from './utils/configs';
import { getAccessPendingEvent, getRealTimeAccessNopag, OpenDoor } from 'src/services/monitoring';
import { RiDoorOpenFill } from 'react-icons/ri';
import { execToast } from 'src/_metronic/_partials/controls/Toast';
import { Loading } from 'src/_metronic/layout/components/Loading';
import { getAllDevices } from 'src/services/permissionLogs';
import { TableContainerB } from './components/Table/styles';
import { DevicesExceptionMode } from 'src/_metronic/_partials/controls/DevicesExceptionMode/DevicesExceptionMode';
import { formatFullDateStyle } from 'src/utils/formatFullDateStyle';
import { defaultDateTime } from 'src/utils/defaultDateTime';

const IMAGES: any = {
  1: 'iDAccess-Perspectiva.jpg',
  2: 'iDAccess_nano.jpg',
  3: 'iDAccess_pro.jpg',
  4: 'iDAccess-Prox-Perspectiva.jpg',
  5: 'iDBlock-Perspectiva.jpg',
  6: 'idblock_balcao.jpg',
  7: 'idblock_bqc.jpg',
  8: 'idblock_facial.jpg',
  15: 'idblock_next.jpg',
  9: 'idblock_pne.jpg',
  10: 'iDBox-Perspectiva.jpg',
  11: 'iDFace.jpg',
  12: 'iDAccess-4x2-Perspectiva.jpg',
  13: 'iDFlex-Frontal.jpg',
  14: 'iDUHF.jpg',
  16: 'iDFaceMax.webp',
  17: 'iduhf-lite.webp'
};

const selectStyle = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    border: '1px solid #E4E6EF',
    '&:hover': {
      cursor: 'pointer'
    }
  })
};

interface LOGSPROPS {
  datetime: string;
  name: string;
  area: string;
  device: string;
  authorizated: string;
  id: number;
  eventClass: boolean;
}

let realTimeController: NodeJS.Timeout | null;
let page = 1;
let isInitalRequest = true;
let idPreSeted: number[] = [];
let idAreas: number[] = [];
let idTypes: number[] = [];
let idDevices: number[] = [];

let primaryIdLog = 0;
let lastIdLog = 0;
let alterFilter = false;

let noOtherDataCard = false;

export function Monitoring() {
  const intl = useIntl();

  const [areaSelecteds, setAreaSelected] = useState<any[]>([]);
  const [typesLogsSelecteds, setTypesLogsSelected] = useState<any[]>([]);
  const [devicesSelecteds, setDevicesSelecteds] = useState<any[]>([]);
  const [logs, setLogs] = useState<LOGSPROPS[]>([]);

  const LoadRef = useRef<HTMLDivElement>(null);
  const emptyRef = useRef<HTMLDivElement>(null);

  const [isFiltersVisibility, setIsFiltersVisibility] = useState(true);

  const [logIsActived, setLogIsActived] = useState({
    datetime: '',
    name: '',
    area: '',
    auth: '',
    image: '',
    eventClass: false,
    id: 0,
    deviceId: 0,
    deviceName: '',
    deviceImage: '',
    userId: 0
  });

  const getAreas = async (value: string) => {
    const {
      data: {
        data: { data: areas, pages }
      }
    } = await getAllAreas({
      pageSize: 100,
      pageNumber: 1,
      // pageNumber: areasPage,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name'
    });

    return {
      options: areas.map((el: any) => {
        return {
          value: el.id,
          label: el.name === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : el.name
        };
      }),
      hasMore: false
    };
  };

  const getTypesAccess = async (value: string) => {
    const typesRegistry = [
      // {
      //   value:2,
      //   label:intl.formatMessage({id: 'EVENTS.INVALID_ID_PARAMS'})
      // },
      {
        value: 3,
        label: intl.formatMessage({ id: 'EVENTS.NOT_IDENTIFIED' })
      },
      {
        value: 4,
        label: intl.formatMessage({ id: 'EVENTS.PENDING_ID' })
      },
      {
        value: 5,
        label: intl.formatMessage({ id: 'EVENTS.ID_TIMEOUT' })
      },
      {
        value: 6,
        label: intl.formatMessage({ id: 'EVENTS.ACCESS_DENIED' })
      },
      {
        value: 7,
        label: intl.formatMessage({ id: 'EVENTS.ACCESS_GRANTED' })
      },
      {
        value: 8,
        label: intl.formatMessage({ id: 'EVENTS.ACCESS_PENDING' })
      },
      // {
      //   value:9,
      //   label:intl.formatMessage({id: 'EVENTS.NOT_ADMIN'})
      // },
      {
        value: 10,
        label: intl.formatMessage({ id: 'EVENTS.NOT_ID_ACCESS' })
      },
      {
        value: 11,
        label: intl.formatMessage({ id: 'EVENTS.BUTTONHOLE_ACCESS' })
      },
      {
        value: 12,
        label: intl.formatMessage({ id: 'EVENTS.WEB_INTERFACE_ACCESS' })
      },
      // {
      //   value:13,
      //   label:intl.formatMessage({id: 'EVENTS.GIVEUP_ACCESS'})
      // },
      // {
      //   value:14,
      //   label:intl.formatMessage({id: 'EVENTS.NO_ANSWER'})
      // },
      {
        value: 15,
        label: intl.formatMessage({ id: 'EVENTS.INTERFONIA_ACCESS' })
      }
    ];

    return {
      options: typesRegistry,
      hasMore: false
    };
  };

  async function getLogs() {

    const { data } = await getRealTimeAccessNopag({
      id: lastIdLog,
      oldest: true,
      idAreas: idAreas.toString(),
      types: idTypes.toString(),
      devices: idDevices.toString()
    });

    const registerFormat = data.data
      .filter((item: any) => !idPreSeted.includes(item.id))
      .map((item: any) => {
        const acesssType = eventeTypeName(+item.event);
        return {
          ...item,
          datetime: defaultDateTime.labelDateTime(item.time),
          name: item.personName,
          area: item.areaName === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : item.areaName,
          authorizated: acesssType,
          id: item.id,
          device: item.deviceName,
          eventClass: [7, 11, 12, 15].includes(item.event)
        };
      });

    try {
      const lastItemIndex = registerFormat.length - 1;
      lastIdLog = registerFormat[lastItemIndex].id;

      // alert(page)
      setLogs((currentValues) => {
        return [...currentValues, ...registerFormat];
      });
    } catch (e) { }
  }

  function eventeTypeName(value: number) {
    switch (value) {
      case 1:
        return intl.formatMessage({ id: 'EVENTS.INVALID_DEVICE' });
      case 2:
        return intl.formatMessage({ id: 'EVENTS.INVALID_ID_PARAMS' });
      case 3:
        return intl.formatMessage({ id: 'EVENTS.NOT_IDENTIFIED' });
      case 4:
        return intl.formatMessage({ id: 'EVENTS.PENDING_ID' });
      case 5:
        return intl.formatMessage({ id: 'EVENTS.ID_TIMEOUT' });
      case 6:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_DENIED' });
      case 7:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_GRANTED' });
      case 8:
        return intl.formatMessage({ id: 'EVENTS.ACCESS_PENDING' });
      case 9:
        return intl.formatMessage({ id: 'EVENTS.NOT_ADMIN' });
      case 10:
        return intl.formatMessage({ id: 'EVENTS.NOT_ID_ACCESS' });
      case 11:
        return intl.formatMessage({ id: 'EVENTS.BUTTONHOLE_ACCESS' });
      case 12:
        return intl.formatMessage({ id: 'EVENTS.WEB_INTERFACE_ACCESS' });
      case 13:
        return intl.formatMessage({ id: 'EVENTS.GIVEUP_ACCESS' });
      case 14:
        return intl.formatMessage({ id: 'EVENTS.NO_ANSWER' });
      case 15:
        return intl.formatMessage({ id: 'EVENTS.INTERFONIA_ACCESS' });
      default:
        return '';
    }
  }

  function loadingHide() {
    if (LoadRef && LoadRef.current) {
      LoadRef.current.style.display = 'none';
    }
  }

  function loadingShow() {
    if (LoadRef && LoadRef.current) {
      LoadRef.current.style.display = 'block';
    }
  }

  function emptyHide() {
    if (emptyRef && emptyRef.current) {
      emptyRef.current.style.display = 'none';
    }
  }

  function emptyShow() {
    if (emptyRef && emptyRef.current) {
      emptyRef.current.style.display = 'block';
    }
  }

  let logsIds: any;

  function RealTime() {
    if (realTimeController) {
      clearTimeout(realTimeController);
    }

    realTimeController = setTimeout(
      () => {
        new Promise(async (response, error) => {
          try {
            const { data } = await getRealTimeAccessNopag({
              id: primaryIdLog,
              oldest: false,
              idAreas: idAreas.toString(),
              types: idTypes.toString(),
              devices: idDevices.toString()
            });

            const hasAccessPendingEventList = data.data?.filter((item: any) => item.accessPendingEvent === true);
            if (hasAccessPendingEventList.length > 0) {
              logsIds = data.data?.filter((item: any) => item.accessPendingEvent === true);
            }
            if (logsIds?.length > 0) {
              const responseAccessPendingEvent = await getAccessPendingEvent({
                ids: logsIds?.map((item: any) => item?.id).join(',')
              });

              const hasAccessPendingEventCheckedList: any = responseAccessPendingEvent?.data.data?.filter(
                (item: any) => item.accessPendingEvent === false
              );


              if (hasAccessPendingEventCheckedList?.length > 0) {
                // RealTime();

                // NOVO SCRIPT
                const { data } = await getRealTimeAccessNopag({
                  id: 0,
                  oldest: false,
                  idAreas: idAreas.toString(),
                  types: idTypes.toString(),
                  devices: idDevices.toString()
                });
                const datasFiltred = data.data?.map((item: any) => {
                  // idPreSeted.push(item.id);

                  const acesssType = eventeTypeName(+item.event);

                  return {
                    ...item,
                    datetime: defaultDateTime.labelDateTime(item.time),
                    name: item.personName,
                    area:
                      item.areaName === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : item.areaName,
                    // authorizated: item.eventDescription,
                    authorizated: acesssType,
                    device: item.deviceName,
                    id: item.id,
                    eventClass: [7, 11, 12, 15].includes(item.event)
                  };
                });

                setLogs(datasFiltred);
              }
            }

            response(data.data);
          } catch (e) {
            error(e);
          }
        })
          .then((response: any) => {

            if (alterFilter) {
              idPreSeted = [];
              primaryIdLog = 0;
            }

            const datasFiltred = response
              .filter((item: any) => !idPreSeted.includes(item.id))
              .map((item: any) => {
                idPreSeted.push(item.id);

                const acesssType = eventeTypeName(+item.event);

                return {
                  ...item,
                  datetime: defaultDateTime.labelDateTime(item.time),
                  name: item.personName,
                  area: item.areaName === 'AREA.DEFAULT' ? intl.formatHTMLMessage({ id: 'AREA.DEFAULT' }) : item.areaName,
                  // authorizated: item.eventDescription,
                  authorizated: acesssType,
                  device: item.deviceName,
                  id: item.id,
                  eventClass: [7, 11, 12, 15].includes(item.event)
                };
              });

            if (alterFilter) {
              // alert('aqui o problema')
              alterFilter = false;
              idPreSeted = [];
              setLogs(datasFiltred);
              loadingHide();

              if (datasFiltred.length === 0) {
                emptyShow();
              } else {
                emptyHide();
              }
              // setTimeout(loadingHide,100)
            } else {
              setLogs((currentValues) => {
                if (datasFiltred.length === 0 && currentValues.length === 0) {
                  emptyShow();
                } else {
                  emptyHide();
                }

                const total = [...datasFiltred, ...currentValues];

                if (total.length > 100) return datasFiltred;

                return [...datasFiltred, ...currentValues];
              });

              if (datasFiltred.length > 0 && !noOtherDataCard) {
                const itemOne = datasFiltred[0];
                setLogIsActived({
                  datetime: itemOne.datetime,
                  area: itemOne.area,
                  auth: itemOne.authorizated,
                  name: itemOne.name,
                  image: !itemOne.personAvatar ? '' : itemOne.personAvatar,
                  eventClass: [7, 11, 12, 15].includes(itemOne.event),
                  id: itemOne.id,
                  deviceId: itemOne.deviceId,
                  deviceName: itemOne.deviceName,
                  deviceImage: itemOne.deviceModelType,
                  userId: !itemOne.personId ? 0 : itemOne.personId
                });
              }
            }

            if (datasFiltred.length > 0 && logIsActived.name === '') {
              const logActived = datasFiltred[0];
              primaryIdLog = logActived.id;

              if (!lastIdLog) {
                const lastItemIndex = datasFiltred.length - 1;
                lastIdLog = datasFiltred[lastItemIndex].id;
              }
            }

            if (isInitalRequest) {
              isInitalRequest = false;

              loadingHide();
            }

            setTimeout(RealTime, 300);
          })
          .catch(() => {
            // RealTime();
            //alert('Deu Erro ')
          });
      },
      lastIdLog ? 5000 : 1000
    );
  }

  function handleActivedLogById(id: number) {
    const logActived: any = logs.find((log) => log.id === id);


    if (!logActived) return;

    setLogIsActived({
      datetime: logActived.datetime,
      area: logActived.area,
      auth: logActived.authorizated,
      name: logActived.name,
      image: !logActived.personAvatar ? '' : logActived.personAvatar,
      eventClass: [7, 11, 12, 15].includes(logActived.event),
      id: logActived.id,
      deviceId: logActived.deviceId,
      deviceName: logActived.deviceName,
      deviceImage: logActived.deviceModelType,
      userId: !logActived.personId ? 0 : logActived.personId
    });

    noOtherDataCard = true;
  }

  async function handleOpenDoor(id: number) {
    try {
      await OpenDoor(id);
      execToast('success', 'Porta aberta', 3000);
    } catch (e) {
      execToast('error', 'Não foi possivel abrir a porta', 3000);
    }
  }

  const getDevices = async (value: string) => {
    const {
      data: {
        data: { data: devices, pages }
      }
    } = await getAllDevices({
      // deviceTypes: '1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16',
      pageSize: 100,
      pageNumber: 1,
      // pageNumber: devicesPage,
      status: 1,
      ...(value && { value }),
      sortOrder: 'asc',
      sortField: 'Name'
    });

    return {
      options: devices.map((el: any) => ({ value: el.id, label: el.name })),
      hasMore: false
    };
  };

  function resetMonitor() {
    setLogIsActived({
      datetime: '',
      name: '',
      area: '',
      auth: '',
      image: '',
      eventClass: false,
      id: 0,
      deviceId: 0,
      deviceName: '',
      deviceImage: '',
      userId: 0
    });
  }

  useEffect(() => {
    primaryIdLog = 0;
    lastIdLog = 0;
    const values = loadConfig();

    setAreaSelected(!values ? [] : values);

    if (values && values.length > 0) {
      idAreas = values.map((item: any) => item.value);

    }

    const valuesTypes = loadConfigTypes();

    setTypesLogsSelected(!valuesTypes ? [] : valuesTypes);

    if (valuesTypes && valuesTypes.length > 0) {
      idTypes = valuesTypes.map((item: any) => item.value);
    }

    const valuesDevices = loadConfigDevices();

    setDevicesSelecteds(!valuesDevices ? [] : valuesDevices);

    if (valuesDevices && valuesDevices.length > 0) {
      idDevices = valuesDevices.map((item: any) => item.value);
    }
  }, []);

  useEffect(() => {
    primaryIdLog = 0;
    lastIdLog = 0;
    RealTime();

    return () => {
      try {
        clearTimeout(realTimeController!);
      } catch { }

      page = 1;
      idPreSeted = [];
      idAreas = [];
      isInitalRequest = true;
      primaryIdLog = 0;
      lastIdLog = 0;
      alterFilter = false;
      noOtherDataCard = false;
    };
  }, []);

  return (
    <Card fluidHeight={null} className={null}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className='monitoring'>
        <CardHeader
          icon={null}
          toolbar={null}
          className={null}
          labelRef={null}
          sticky={false}
          linkAddTitle={undefined}
          title={intl.formatMessage({ id: 'ITEM_MENU_MONITOR' })}
        />

        <DevicesExceptionMode />
      </div>
      <CardBody fit={null} fluid={null} className={null}>
        <MonitorFilter isVisibility={isFiltersVisibility}>
          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ACCESS_LOGS.INPUTS.AREAS' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getAreas}
              loadOptionsOnMenuOpen
              isMulti
              value={areaSelecteds}
              onChange={(values: any) => {
                primaryIdLog = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();

                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                saveConfig(values);
                setAreaSelected(values);
                idAreas = ids;

                alterFilter = true;
              }}
            />
          </div>

          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ACCESS_TYPE' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getTypesAccess}
              loadOptionsOnMenuOpen
              isMulti
              value={typesLogsSelecteds}
              onChange={(values: any) => {
                primaryIdLog = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();

                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                //saveConfig(values);
                setTypesLogsSelected(values);

                alterFilter = true;
                idTypes = ids;
                saveConfigTypes(values);
              }}
            />
          </div>

          <div className='filter'>
            <label className={'form-label'}>{intl.formatMessage({ id: 'ACCESS_LOGS.COLUMNS.DEVICE' })}</label>
            <AsyncPaginate
              loadingMessage={() => intl.formatMessage({ id: 'PERSON.PERSONS_TABLE_LOADING' })}
              noOptionsMessage={() => intl.formatMessage({ id: 'NOTHING_FOUND' })}
              debounceTimeout={500}
              styles={selectStyle}
              closeMenuOnSelect={false}
              placeholder={intl.formatMessage({ id: 'ACCESS_LOGS.PLACEHOLDERS.SELECT' })}
              loadOptions={getDevices}
              loadOptionsOnMenuOpen
              isMulti
              value={devicesSelecteds}
              onChange={(values: any) => {
                primaryIdLog = 0;
                setLogs([]);
                resetMonitor();
                loadingShow();
                const ids = values ? [...values.map(({ value }: any) => value)] : [];

                //saveConfig(values);
                setDevicesSelecteds(values);

                alterFilter = true;
                idDevices = ids;
                saveConfigDevices(values);
              }}
            />
          </div>

          <div className='hide'>
            <button className='btn btn-default' onClick={() => setIsFiltersVisibility(false)}>
              <MdFilterAlt size='18' />
            </button>
          </div>
        </MonitorFilter>

        {!isFiltersVisibility && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end'
            }}>
            <button
              style={{
                marginRight: '2.2%'
              }}
              className='btn btn-primary'
              onClick={() => setIsFiltersVisibility(true)}>
              <MdFilterAlt size='18' />
            </button>
          </div>
        )}
        <hr className='MuiDivider-root mb-5 MuiDivider-middle' />

        <MonitoringContainer>
          <h6>{intl.formatMessage({ id: 'MONITORING_PAGE_LAST_ACCESS' })}</h6>

          <div
            style={{
              display: 'flex'
            }}>
            <div className='containerTableAndBox' style={!logIsActived.id ? { width: '100%' } : {}}>
              <Table
                data={logs}
                nextPage={() => {
                  getLogs();
                }}
                selected={handleActivedLogById}
                idActived={logIsActived.id}
              />

              <div ref={emptyRef} style={{ display: 'none' }}>
                <TableContainerB responsive>
                  <thead style={{ display: 'none' }}>
                    <tr>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'DATE_TIME_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'NAME_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'AREA_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'DEVICE_LABEL' })}
                      </th>
                      <th style={{ color: '#B5B5C3', fontWeight: '600', textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'AUTHORIZATION_LABEL' })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>{intl.formatMessage({ id: 'NO.REGISTERS' })}</tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                  </tbody>
                </TableContainerB>
              </div>

              <div ref={LoadRef} style={{ height: '15vh' }}>
                <div className='loader-control'>
                  <Loading />
                </div>
              </div>
            </div>

            <div
              style={{
                position: 'relative',

                display: logIsActived.id ? 'flex' : 'none',
                padding: '0px 10px',
                justifyContent: 'space-between'
                // height: '142px'
              }}>
              {/* <MonitorResume isVisible={!!logIsActived .id}> */}

              <CardMonitor
                data={{
                  area: logIsActived.area,
                  auth: logIsActived.auth,
                  datetime: logIsActived.datetime,
                  deviceImage: `/media/devices/${IMAGES[logIsActived.deviceImage]}`,
                  eventClass: logIsActived.eventClass,
                  deviceName: logIsActived.deviceName,
                  imageURL: logIsActived.image,
                  name: logIsActived.name,
                  deviceId: logIsActived.deviceId,
                  userId: logIsActived.userId
                }}
                openDoor={handleOpenDoor}
                hide={() => {
                  noOtherDataCard = false;
                  resetMonitor();
                }}
                type={logIsActived.deviceImage}
              />
            </div>
          </div>
        </MonitoringContainer>
      </CardBody>
    </Card>
  );
}
