export type ConfigSystemProps = {
  language: 'pt' | 'en' | 'es';
  personCardType: number;
  personDocumentType: string;
  timeZone: number;
  timeZoneCode: string;
};

export enum ActionTypes {
  SET_CONFIG_SYSTEM_LANGUAGUE = 'SET_CONFIG_SYSTEM_LANGUAGUE',
  SET_CONFIG_SYSTEM_PERSON_CARD = 'SET_CONFIG_SYSTEM_PERSON_CARD',
  SET_CONFIG_SYSTEM_PACK = 'SET_CONFIG_SYSTEM_PACK'
}
