import { Reducer } from 'redux';
import { ConfigSystemProps, ActionTypes } from './types';

import produce from 'immer'; //immer

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig';

export const getLanguage = (): { selectedLang: "pt" | "en" | "es" } => {
  const LANGUAGUE_DEFAULT = localStorage.getItem(I18N_CONFIG_KEY);

  return LANGUAGUE_DEFAULT ? JSON.parse(LANGUAGUE_DEFAULT) : { selectedLang: 'pt' }
}

const LANGUAGUE = getLanguage();

const INITIAL_STATE: ConfigSystemProps = {
  language: LANGUAGUE.selectedLang,
  personCardType: 1,
  personDocumentType: 'rg',
  timeZone: -3,
  timeZoneCode: 'America/Sao_Paulo'
};

const configSystem: Reducer<ConfigSystemProps> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_CONFIG_SYSTEM_LANGUAGUE: {
        const { payload } = action;
        draft.language = payload;
        break;
      }

      case ActionTypes.SET_CONFIG_SYSTEM_PERSON_CARD: {
        const { payload } = action;
        draft.personCardType = payload;
        break;
      }

      case ActionTypes.SET_CONFIG_SYSTEM_PACK: {
        const { payload } = action;

        draft.language = payload.language;
        draft.personCardType = payload.personCardType;
        draft.personDocumentType = payload.personDocumentType;
        draft.timeZone = payload.timeZone;
        draft.timeZoneCode = payload.timeZoneCode;
        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default configSystem;
